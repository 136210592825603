import {
  faAngleDown,
  faBars,
  faBoxes,
  faBriefcase,
  faCity,
  faCheck,
  faChevronDown,
  faGift,
  faGlobeAmericas,
  faHome,
  faListUl,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMobileAlt,
  faPhone,
  faPlusCircle,
  faSignOutAlt,
  faSyncAlt,
  faUsers,
  faTimes,
  faTransgender,
  faTruck,
  faUser as faUserSolid,
  faSign,
  faStreetView,
  faUserTie,
  faBarcode,
  faCreditCard,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopeSquare,
  faHeadset,
  faInfoCircle,
  faUserLock,
  faLock,
  faBox,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

export default {
  faAngleDown,
  faBars,
  faBoxes,
  faBriefcase,
  faCity,
  faGlobeAmericas,
  faCheck,
  faChevronDown,
  faGift,
  faHome,
  faListUl,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMobileAlt,
  faPhone,
  faPlusCircle,
  faSignOutAlt,
  faSyncAlt,
  faUsers,
  faTimes,
  faTransgender,
  faTruck,
  faUserSolid,
  faSign,
  faStreetView,
  faUserTie,
  faBarcode,
  faCreditCard,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopeSquare,
  faHeadset,
  faInfoCircle,
  faUserLock,
  faLock,
  faBox,
  faDollarSign,
};
