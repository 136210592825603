import { createApp } from "vue";
import mitt from "mitt";
import store from "./store";
import router from "./router";
import httpPlugin from "./plugins/http";
import dateTimePlugin from "./plugins/dateTime";
import App from "./App";
import { sync } from "vuex-router-sync";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

require("./includes");

const isProd = process.env.NODE_ENV === "production";
const emitter = mitt();
const app = createApp(App);

sync(store, router);

// Debug
app.config.globalProperties.devtools = !isProd;
app.config.globalProperties.performance = !isProd;
app.config.globalProperties.$bus = emitter;

app.use(router);
app.use(store);
app.use(httpPlugin, { store });
app.use(dateTimePlugin);
app.mount("#app");

export default app;
