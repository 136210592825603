/* eslint-disable class-methods-use-this */

import BaseModel from "@/store/models/base";

const Icons = {
  Default: null,
  Canceled: { prefix: "fa", name: "times" },
  Ordered: { prefix: "far", name: "clipboard" },
  Payment: { prefix: "far", name: "money-bill-alt" },
  Shipment: { prefix: "fa", name: "truck" },
  Delivery: { prefix: "fa", name: "check" },
  Withdraw: { prefix: "fa", name: "box" },
};

/**
 * @abstract
 * */
class StepType extends BaseModel {
  /**
   * {@inheritDoc}
   * */
  getSchema() {
    return {
      name: "",
      current: "",
      date: "",
      label: "",
      status: "",
      action: {
        label: "",
        link: "",
      },
    };
  }

  /**
   * Retorna o nome do passo.
   *
   * @return {string}
   * */
  getStepName() {
    return this.name;
  }

  /**
   * Determina se o passo é do tipo fornecido.
   *
   * @param {string} type
   * @return {string}
   * */
  isTypeOf(type) {
    return this.getStepName() === type;
  }

  /**
   * Determina se o pedido está em aberto.
   *
   * @return {boolean}
   * */
  isOpen() {
    return this.status === "open";
  }

  /**
   * Determina se o pedido foi concluído.
   *
   * @return {boolean}
   * */
  isClosed() {
    return this.status === "closed";
  }

  /**
   * Determina se o pedido foi cancelado.
   *
   * @return {boolean}
   * */
  isCanceled() {
    return this.status === "canceled";
  }

  /**
   * Retorna o ícone usado pelo passo.
   *
   * @return {{name:string, prefix: string}}
   * */
  get icon() {
    if (this.isCanceled()) {
      return Icons.Canceled;
    }

    return Icons[this.getStepName()] || Icons.Default;
  }

  /**
   * Retorna o rótulo da ação do passo.
   *
   * @return {boolean}
   * */
  getActionLabel() {
    return this.action.label;
  }

  /**
   * Retorna o link da ação do passo.
   *
   * @return {boolean}
   * */
  getActionLink() {
    return this.action.link;
  }

  /**
   * Determina se o passo possui link em sua ação.
   *
   * @return {boolean}
   * */
  hasActionLink() {
    return !!this.getActionLink();
  }
}

export default StepType;
