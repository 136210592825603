export default {
  name: "ValidateAmbient",

  computed: {
    /**
     * Valida se é um ambiente de teste
     * @return {bool}
     */
    isTestAmbient() {
      return process.env.NODE_ENV === "test";
    },

    /**
     * Valida se é um ambiente de produção
     * @return {boolean}
     */
    isProductionAmbient() {
      return process.env.NODE_ENV === "production";
    },
  },
};
