export default {
  customer: {
    list: {
      birth_date: "",
      cpf: "",
      company: {
        cnpj: "",
        name: "",
        state_inscription: "",
      },
      email: "",
      id: "",
      mobile_phone: "",
      name: "",
      phone: "",
      rg: "",
      gender: "",
      type: "",
      profile: {
        name: "",
      },
      customer_attributes: {},
      newletter: 0,
    },
    profiles: [],
    selectedProfile: "",
    loading: true,
    messages: [],
    isPersonalDataInvalid: true,
    isBusinessDataInvalid: true,
    isPersonalAttributeDataInvalid: false,
    isBusinessAttributeDataInvalid: false,
    isBothAttributeDataInvalid: false,
    backendErrors: {
      email: "",
      name: "",
      cpf: "",
      birth_date: "",
      phone: "",
      mobile_phone: "",
      rg: "",
      gender: "",
      cnpj: "",
      state_inscription: "",
      company_name: "",
      password: "",
      confirmation: "",
      "extensions.attributes": "",
    },
    vuelidateErrors: {
      email: true,
      name: true,
      cpf: true,
      birth_date: true,
      phone: true,
      cellphone: true,
      rg: true,
      gender: true,
      cnpj: true,
      state_inscription: true,
      company_name: true,
    },
  },
};
