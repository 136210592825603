/**
 * Ordena os endereços para que o endereço principal esteja sempre em primeiro
 * @param {objec} addresses
 * @return {void}
 */
const sortAddressesByMain = (addresses) => {
  addresses.sort((a, b) => {
    if (a.active > b.active) {
      return -1;
    }

    if (a.active < b.active) {
      return 1;
    }

    return 0;
  });
};

/**
 * Retorna o primeiro endereço de cobrança ativo
 * @param {object} state State de Address
 * @return {object|bool} Objeto do endereço, ou false caso não houver nenhum
 */
export const billingAddress = (state) => {
  const { addresses } = state.address;
  const billingAddrs = addresses.filter((addr) => addr.type === "0");

  return billingAddrs[0];
};

/**
 * Retorna todos os endereços de entrega, excluindo o endereço de cobrança
 * @param {object} state State de Address
 * @return {object|bool} Objeto do endereço, ou false caso não houver nenhum
 */
export const deliveryAddresses = (state) => {
  const { addresses } = state.address;
  const deliveryAddrs = addresses.filter((addr) => addr.type !== "0");

  sortAddressesByMain(deliveryAddrs);
  return deliveryAddrs;
};
