/* eslint-disable class-methods-use-this */

import StepType from "./step.type";

export default class DefaultStepModel extends StepType {
  /**
   * {@inheritDoc}
   * */
  getStepName() {
    return "Default";
  }
}
