/* eslint-disable no-unused-vars */
import { createStore } from "vuex";
import { analyticsMiddleware } from "vue-analytics";

import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import modules from "./modules";
import * as getters from "./getters";

const store = createStore({
  state,
  mutations,
  actions,
  getters,
  modules,
  plugins: [analyticsMiddleware],
  strict: process.env.NODE_ENV !== "production",
});

export default store;
