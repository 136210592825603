export default {
  /**
   * Limpa do estado e consequentemente
   * dos campos o endereço atual
   * @param {object} state
   */

  clearAddress(state) {
    Object.assign(state.address.address, {
      id: "",
      active: "",
      city: "",
      complement: "",
      country: "BRA",
      description: "",
      neighborhood: "",
      number: "",
      recipient: "",
      state: "",
      street: "",
      type: "",
      zip_code: "",
    });
  },

  /**
   * Limpa os erros provenientes do backend
   * @param {object} state
   */
  clearBackendErrors(state) {
    state.address.backendErrors = {
      customer_id: "",
      active: "",
      city: "",
      complement: "",
      country: "BRA",
      id: "",
      description: "",
      neighborhood: "",
      number: "",
      recipient: "",
      state: "",
      address: "",
      type: "",
      zip_code: "",
    };
  },

  /**
   * Limpa o array de mensagens
   * @param {object} state
   */
  clearMessages(state) {
    state.address.messages.length = 0;
  },

  /**
   * Remove um endereço da lista de endereços
   * @param {object} state
   * @param {addressId} addressId Identificador do endereço que será removido
   */
  deleteAddress(state, id) {
    if (!id) {
      return;
    }

    const addressIndex = state.address.addresses.findIndex(
      (address) => address.id === id
    );

    if (addressIndex === -1) {
      return;
    }

    state.address.addresses.splice(addressIndex, 1);
  },
  /**
   * Define uma nova lista de endereços
   * @param {object} state
   * @param {object} addresses
   */
  setAddresses(state, addresses) {
    if (addresses.addresses) {
      state.address.addresses = addresses.addresses;
    }
  },

  /**
   * Define as propriedades do endereços
   * @param {object} state State de Address
   * @param {object} address objeto com as propriedades que devem ser alteradas
   */
  setAddress(state, address) {
    state.address.address = { ...state.address.address, ...address };
  },

  /**
   * Seta a mensagem de feedback
   * @param {object} state
   * @param {object} message Mensagem
   */
  setMessage(state, message) {
    if (typeof message === "object" && message.text) {
      state.address.messages.push(message);
    }
  },

  /**
   * Seta os erros provenientes do backend
   * @param {object} state
   * @param {object} errors Objeto com os erros que devem ser alterados
   */
  setBackendErrors(state, errors) {
    state.address.backendErrors = {
      ...state.address.backendErrors,
      ...errors,
    };
  },
};
