import VueGtag from "vue-gtag-next";

/**
 * Google Tag Manager
 */
import gtmPlugin from "./gtm";
import router from "../../router";
import app from "../../main";

/**
 * Retorna o codigo analytics da tray de acordo com o plano da loja
 * @param { object } storeInfo informacoes da loja
 * @return { string } analytics code
 */
function getTrayInternalTracker(
  storeInfo = {
    id: 0,
    plan: "",
  }
) {
  const { plan, id: storeId } = storeInfo;
  if (!storeId || !plan) {
    return "";
  }

  const trackers = {
    basic: "UA-6914032-31",
    corporativo: "UA-6914032-20",
    exclusive: "UA-6914032-20",
    futfanatics: "UA-6914032-30",
  };

  const isFutFanatics = storeId === 311840;
  if (isFutFanatics) {
    return trackers.futfanatics;
  }

  return trackers[plan] || trackers.basic;
}

/**
 * Retorna os trackers disponíveis na loja
 * @returns {Promise}
 */
function getTrackers(
  payload = {
    googleIntegrations: {
      analytics: "",
      tagmanager: "",
    },
    storeInfo: {
      id: 0,
      plan: "",
      features: [],
    },
  }
) {
  return new Promise((resolve) => {
    const { googleIntegrations, storeInfo } = payload;
    const trackers = [];
    const storeAnalytics = googleIntegrations.analytics;
    const gtmId = googleIntegrations.tagmanager;

    if (storeAnalytics) {
      trackers.push(storeAnalytics);
    }

    const allowInternalAnalytics = storeInfo.features.includes(
      "internal-analytics-tray"
    );
    if (allowInternalAnalytics) {
      const trayInternalTracker = getTrayInternalTracker(storeInfo);
      trackers.push(trayInternalTracker);
    }

    /**
     * Verifica se existe algum tracker inserido no GTM
     */
    const startAt = Date.now();
    const TIMEOUT = 2000;
    const getGtmTracking = () => {
      const timeElapsed = Date.now() - startAt;
      if (!gtmId || timeElapsed > TIMEOUT) {
        return resolve(trackers);
      }

      if (window.ga && window.ga.loaded) {
        window.ga
          .getAll()
          .map((tracker) => trackers.push(tracker.get("trackingId")));

        return resolve(trackers);
      }

      return setTimeout(getGtmTracking, 300);
    };

    getGtmTracking();
  });
}

/**
 * Inicializa os trackers da aplicação
 */
export default function setTrackers(
  payload = {
    googleIntegrations: { analytics: "", tagmanager: "" },
    storeInfo: { id: 0, plan: "", features: [] },
    ga4Code: "",
  }
) {
  const { googleIntegrations, ga4Code } = payload;
  /**
   * Inicializa o tracking do Google Tag Manager
   * Veja ./plugins/google/gtm'
   */
  const gtmId = googleIntegrations.tagmanager;

  if (gtmId) {
    app.use(gtmPlugin, { id: gtmId, router });
  }

  /**
   * Inicializa o tracking do Google Analytics
   */
  getTrackers(payload).then((trackers) => {
    // Global site tag (gtag.js) - Google Analytics
    if (ga4Code && ga4Code != 0) {
      const scriptGtag1 = document.createElement("script");
      scriptGtag1.async = true;
      scriptGtag1.src = `https://www.googletagmanager.com/gtag/js?id=${ga4Code}`;
      document.head.appendChild(scriptGtag1);
      const scriptGtag2 = document.createElement("script");
      scriptGtag2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${ga4Code}');
        `;
      document.head.appendChild(scriptGtag2);
    }

    // Tags do GA UA usando vue-gtag
    if (trackers.length) {
      const trackersProperty = new Set([]);

      trackers.forEach((item) => {
        trackersProperty.add({ id: item });
      });

      app.use(VueGtag, {
        property: [...trackersProperty],
      });
    }
  });
}
