const Styles = {
  button_primary_background: "#6abf57",
  button_primary_border: "#539e41",
  button_primary_text: "#ffffff",
  button_primary_background_hover: "#51a43f",
  button_primary_border_hover: "#407a32",
  button_primary_text_hover: "#ffffff",
  button_secondary_background: "#ffffff",
  button_secondary_border: "#d3d1d1",
  button_secondary_text: "#bcbcbc",
  button_secondary_background_hover: "#e6e6e6",
  button_secondary_border_hover: "#d3d1d1",
  button_secondary_text_hover: "#3d3d3d",
  header_color: "#c3c3c3",
  header_background_color: "#f5f5f5",
  header_border_color: "#dbdada",
  font_family: "Lato",
  footer_color: "#8F8F8F",
  footer_border_color: "#DBDADA",
  footer_background_color: "#F5F5F5",
  footer_safe_color: "#82cb6f",
  link_color: "#656565",
  text_color: "#8f8f8f",
};

export default Styles;
