<script>
import { mapGetters } from "vuex";

export default {
  name: "",

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters(["langs", "storeInfo"]),

    /**
     * Lista as páginas institucionais que retornam da API
     * @return {array}
     */
    pages() {
      return this.storeInfo.custom_pages.data;
    },
  },

  methods: {
    /**
     * Retorna o nome da rota para o rotuer-link poder fazer o redirecionamento
     * @return {string}
     */
    pageName({ url }) {
      return url?.slice(1);
    },
  },
};
</script>

<template>
  <div class="app__footer__institutional">
    <h2 class="app__footer__title" :class="{ app__loading: loading }">
      {{ this.langs.pagination.instititional }}
    </h2>
    <ul class="app__footer__institutional__list">
      <li
        class="app__footer__institutional__list__item"
        v-for="page in pages"
        :key="page.name"
      >
        <router-link
          slots="a"
          :to="{
            name: 'login.institucional',
            params: { name: pageName(page) },
          }"
        >
          {{ page.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
