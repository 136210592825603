import TimelineModel from "@/features/general/Timeline/models/timeline";
import OrderModel from "./models/order";

export default {
  order: {
    messages: [],
    model: new OrderModel(),
    list: {
      customer: {
        email: "",
      },
      id: "",
      status: {
        store: "",
        generic: "em andamento",
        type: "open",
        allow_edit_order: false,
        show: false,
        description: "",
        background_color: "",
      },
      access_code: "",
      addresses: [
        {
          city: "#####",
          neighborhood: "#####",
          number: "###",
          recipient: "#####",
          state: "##",
          street: "###",
          zip_code: "####-####",
          type: "1",
        },
      ],
      shipping: {
        code: "#####",
        estimated_date: "",
        is_traceable: false,
        withdraw: false,
      },
      pickupLocation: {
        address: {
          city: "",
          neighborhood: "",
          street: "",
          state: "",
          zipCode: "#####-###",
        },
        cellphone: "##########",
        code: "########",
        name: "",
        shippingId: "",
        values: {
          value: "",
        },
      },
      payment_method_message: {
        text: "",
        text_pag: "",
        text_confirm: "",
        confirmation: "",
      },
      products: [
        {
          name: "",
          brand: "",
          reference: "",
          quantity: "",
          is_virtual: false,
          total_price: "",
          unit_price: "",
          url: {
            http: "",
            https: "",
          },
          is_giveaway: false,
          rating: 0,
          ProductImage: [
            {
              thumbs: {
                30: {
                  https: "",
                },
              },
            },
          ],
        },
      ],
      invoice: {
        link: "",
      },
      calculator: {
        coupon: "",
        payment: "",
        taxes: "",
        products: "",
        shipping: "",
        total: "",
      },
      isOpen: false,
    },
    externalShippingTracker: "",
    loading: true,
    timeline: new TimelineModel([
      {
        name: "Ordered",
        date: "",
        current: false,
        label: "Pedido realizado",
        status: "open",
      },
      {
        name: "Payment",
        date: "",
        current: false,
        label: "Aguardando pagamento",
        status: "open",
      },
      {
        name: "Shipment",
        date: "",
        current: false,
        label: "Envio",
        status: "open",
      },
      {
        name: "Delivery",
        date: "",
        current: false,
        label: "Previsão de entrega",
        status: "open",
      },
    ]),
    trackerTimeline: new TimelineModel([
      {
        date: "",
        status: "closed",
        label: "Destinatário retirou objeto na Unidade dos Correios",
        local: "",
      },
      {
        date: "",
        label: "Objeto disponível para retirada",
        local: "",
        status: "closed",
      },
    ]),
    timelineLoading: true,
    productRating: {
      name: "",
      brand: "",
      reference: "",
      quantity: "",
      is_virtual: false,
      total_price: "",
      unit_price: "",
      url: {
        http: "",
        https: "",
      },
      is_giveaway: false,
      rating: 0,
      ProductComment: {
        rating: "0",
        comment: "",
      },
      customer: {
        id: "",
        name: "",
      },
    },
  },
  orders: {
    list: [
      {
        date: "",
        id: 0,
        links: {
          self: "",
        },
        status: {
          store: "",
          generic: "",
          type: "",
          description: "",
          show: "",
          background_color: "",
        },
        total: "",
        urls: {
          code: "",
          payment: "",
          transaction: "",
        },
        invoice: {
          link: "",
        },
        paymentMethod: {
          id: 0,
          rate: 0,
          name: "",
          type: "",
          installment: 0,
        },
        payment_method_message: {
          text: "",
          text_pag: "",
          text_confirm: "",
          confirmation: "",
        },
        access_code: "",
        products: [],
        isOpen: false,
      },
    ],
    pagination: {
      count: "",
      current_page: "",
      links: {},
      per_page: "",
      total: "",
      total_pages: "",
    },
    loading: true,
  },
  loading: true,
};
