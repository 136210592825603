// https://vuex.vuejs.org/en/mutations.html
/* eslint-disable no-param-reassign */
export default {
  setLoading(state, obj) {
    state.customer.loading = obj.loading;
  },

  setData(state, obj) {
    if (obj.list) {
      state.customer.list = { ...state.customer.list, ...obj.list };
    }
  },

  /* eslint-disable camelcase */
  setCustomerAttribute(state, customer_attribute = {}) {
    state.customer.list.customer_attributes = {
      ...state.customer.list.customer_attributes,
      ...customer_attribute,
    };
  },

  setMessage(state, message) {
    if (typeof message === "object") {
      state.customer.messages.push(message);
    }
  },

  setProfiles(state, profile) {
    state.customer.profiles = profile;
  },

  setSelectedProfile(state, profile) {
    state.customer.selectedProfile = profile;
  },

  clearMessages(state) {
    state.customer.messages = [];
  },

  setIsPersonalDataInvalid(state, isInvalid) {
    state.customer.isPersonalDataInvalid = isInvalid;
  },

  setIsBusinessDataInvalid(state, isInvalid) {
    state.customer.isBusinessDataInvalid = isInvalid;
  },

  setIsBusinessAttributeDataInvalid(state, isInvalid) {
    state.customer.isBusinessAttributeDataInvalid = isInvalid;
  },

  setIsPersonalAttributeDataInvalid(state, isInvalid) {
    state.customer.isPersonalAttributeDataInvalid = isInvalid;
  },

  setIsBothAttributeDataInvalid(state, isInvalid) {
    state.customer.isBothAttributeDataInvalid = isInvalid;
  },

  setBackendErrors(state, obj) {
    if (obj.backendErrors) {
      state.customer.backendErrors = {
        ...state.customer.backendErrors,
        ...obj.backendErrors,
      };
    }
  },

  setVuelidateErrors(state, obj) {
    state.customer.vuelidateErrors = {
      ...state.customer.vuelidateErrors,
      ...obj,
    };
  },

  clearBackendErrors(state) {
    state.customer.backendErrors = {
      email: "",
      name: "",
      cpf: "",
      birth_date: "",
      phone: "",
      mobile_phone: "",
      rg: "",
      gender: "",
      cnpj: "",
      state_inscription: "",
      company_name: "",
      password: "",
      confirmation: "",
    };
  },
};
