import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

/**
 * @abstract
 * */
export default class BaseModel {
  /**
   * Armazena os valores originais dos atributos.
   *
   * @example
   * get id () {
   *   return this.rawAttributes.id || '';
   * }
   * @protected
   * @var {object} rawAttributes
   * */
  rawAttributes = {};

  /**
   * BaseModel's constructor
   *
   * @var {object} attributes
   * */
  constructor(attributes = {}) {
    this.fill({ ...this.getSchema(), ...attributes });
    this.onInit();
  }

  /**
   * Callback para inicialização da model.
   *
   * @protected
   * @return {void}
   * */
  onInit() {}

  /**
   * Retorna o esquema de atributos padrão da modal.
   *
   * @protected
   * @return {object}
   * */
  getSchema() {
    return {};
  }

  /**
   * Adiciona os atributos fornecidos a model.
   *
   * @param {object} attributes
   * @return {BaseModel}
   * */
  fill(attributes = {}) {
    Object.keys(attributes || {}).forEach((key) => {
      this.setAttribute(key, attributes[key]);
    });

    return this;
  }

  /**
   * Adiciona o atributo fornecido na modal.
   *
   * @param {string} key
   * @param {any} value
   * @return {BaseModel}
   * */
  setAttribute(key, value) {
    Object.defineProperty(this.rawAttributes, key, {
      enumerable: true,
      configurable: true,
      value: this.mutate(key, value),
      writable: false,
    });

    const descriptor =
      Object.getOwnPropertyDescriptor(this.constructor.prototype, key) || {};
    descriptor.enumerable = true;
    descriptor.configurable = true;
    descriptor.set = () => {
      // eslint-disable-next-line no-console
      console.warn(
        `Cannot directly modify the attribute ${key}. You must call store.dispatch instead.`
      );
    };

    if (!descriptor.get) {
      descriptor.get = () => this.rawAttributes[key];
    }

    Object.defineProperty(this, key, descriptor);
  }

  /**
   * Invoca o método (caso exista) para modificar o valor do atributo fornecido.
   *
   * @param {string} key
   * @param {any} value
   * @return {any}
   * */
  mutate(key, value) {
    const method = `mutate${upperFirst(camelCase(key))}`;
    if (typeof this[method] !== "function") {
      return value;
    }

    return this[method](value);
  }
}
