import axios from "axios";
import router from "@/router/index";
import interceptors from "./interceptors";
import store from "@/store/index";

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Cache-Control": "no-cache",
    Accept: "application/json",
  },
});

export const httpBasic = axios.create({
  headers: { "Cache-Control": "no-cache" },
});

/**
 * Metodo utilizado para setar o token de autorização no header
 */
export function setToken(token) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
}

/**
 * Método remove o token de autorização do header e chama a api de logout para
 * revogar o acesso ao token.
 */
export function revokeToken() {
  http.defaults.headers.common.Authorization = "";
  http.get("/logout");
}

/**
 * Valida se o usuário está logado de acordo com a variável global
 * @return {boolean}
 */
function isUserAuthenticated() {
  const { isUserAuthenticated } = store.state;

  if (!isUserAuthenticated) {
    return Promise.reject(
      new Error("Erro 401 anteriormente. Novas requisições não são permitidas.")
    );
  }

  return http;
}

/**
 * Define o plugin Axios como propriedade do Vue
 */
const install = (app) => {
  interceptors(http, store, router);

  Object.defineProperties(app.config.globalProperties, {
    $http: {
      get() {
        return isUserAuthenticated();
      },
    },
  });
};
export default install;
