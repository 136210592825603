<script>
import AppIcon from "@/features/general/Icons";
import AppDropdown from "@/features/general/Dropdown";
import { mapGetters } from "vuex";

export default {
  name: "AppCustomerGreetings",
  components: {
    AppIcon,
    AppDropdown,
  },

  data() {
    return {
      /**
       * Controle de exibição do menu drop-down
       * @type {Boolean}
       */
      showMenu: false,
    };
  },

  /**
   * @to-do usar modulo de EventBus @/plugins/event-bus
   */
  created() {
    window.addEventListener("click", this.close);
  },

  /**
   * @to-do usar modulo de EventBus @/plugins/event-bus
   */
  beforeUnmount() {
    window.removeEventListener("click", this.close);
  },

  computed: {
    ...mapGetters(["customer", "storeInfo", "mainLoading", "langs"]),

    /**
     * Trata o nome do usuário retornando
     * @returns {String} Primeiro nome do usuário
     */
    customerFirstName() {
      return this.customer.name.split(" ")[0];
    },
  },

  methods: {
    /**
     * Redireciona o usuário para a pagina inicial
     * @returns {String} Url de cadastro
     */
    goToHome() {
      this.$router.push("/");
    },

    toggle() {
      this.showMenu = !this.showMenu;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showMenu = false;
      }
    },
  },
};
</script>

<template>
  <section class="app__customer-greetings" @click.prevent="toggle">
    <figure
      class="app__customer-greetings__avatar-container"
      :class="{ app__loading: mainLoading }"
    >
      <img
        v-if="customer.gravatar"
        class="app__customer-greetings__avatar"
        :src="customer.gravatar"
        :alt="customer.name"
      />
      <app-icon v-else name="user-circle"></app-icon>
    </figure>
    <div class="app__customer-greetings__greeting d-none d-md-block">
      <span
        class="app__customer-greetings__greeting-item"
        :class="{ app__loading: mainLoading }"
      >
        {{ this.langs.pagination.greeting }}, {{ customerFirstName }}
      </span>
    </div>
    <transition
      enter-active-class="app__animated app__animated--fade-in"
      leave-active-class="app__animated app__animated--fade-out"
    >
      <!--
        @TODO ? Um componente lista ao invés de um "DropDown"
        ex <app-list :list="[{name: 'Link1', url: 'http://'}, {name: 'Link2', url: 'http://'}]">
      -->
      <app-dropdown :storeInfo="storeInfo" v-if="showMenu"></app-dropdown>
    </transition>
    <figure
      class="app__customer-greetings__icon"
      :class="{
        app__loading: mainLoading,
      }"
    >
      <app-icon
        name="angle-down"
        prefix="fa"
        :class="{ 'app__icon--rotate180': showMenu }"
      >
      </app-icon>
    </figure>
  </section>
</template>
