<!--
Para evitar conflitos com elementos HTML padrão, utilize o prefixo App antes do nome do componente
-->
<script>
import AppIcon from "@/features/general/Icons";
import { mapGetters, mapState } from "vuex";
import { logout as authLogout } from "@/plugins/auth";
import loginProduct from "@/mixins/loginProduct";

export default {
  name: "Navigation",

  mixins: [loginProduct],

  components: {
    AppIcon,
  },

  data() {
    return {
      plansList: {
        "related-customer": 2339,
        "wish-list": 2311,
        "shopping-list": 2340,
        "file-uploads": 2399,
      },
    };
  },

  methods: {
    /**
     * Realiza o logout do usuário na nova central e redireciona para a url
     * de logout da central antiga.
     * @return {void}
     */
    logout() {
      authLogout();
      this.redirectToCommerceLogout();
    },

    /**
     * Redireciona para a url de logout da Plataforma Commerce.
     * @return {void}
     */
    redirectToCommerceLogout() {
      const storeId = this.store_info.id;
      const secureDomain = this.store_info.uri.https;

      window.location.replace(
        `${secureDomain}/loja/logout.php?loja=${storeId}`
      );
    },

    /**
     * Retorna true caso a configuração estiver habilitada.
     * @param  {string}  setting
     * @return {Boolean}
     */
    hasStoreSettingEnabled(setting) {
      return (
        this.store_info.settings.store[setting] !== "0" &&
        this.store_info.settings.store[setting] !== undefined
      );
    },

    /* Marca como ativo o item do menu caso a rota de navegação
     * seja correspondente
     *
     * @return {Boolean}
     */
    isActive(route) {
      if (!this.$route.name) {
        return false;
      }

      return this.$route.name.indexOf(route) !== -1;
    },

    /**
     * Exibe/Esconde o menu no ambiente mobile
     * @return void
     */
    toggleMenu() {
      this.$bus.emit("toggle-menu");
    },

    /**
     * Exibe o nome do bonus configurado pelo lojista
     * @return string
     */
    getStoreBonus() {
      const bonusName = this.store_info.settings.store.bonus_name.trim();

      if (!bonusName) {
        return `${this.langs.sidebar_menu.points}`;
      }

      const captitalizedName = `${bonusName
        .charAt(0)
        .toUpperCase()}${bonusName.slice(1)}`;
      return captitalizedName;
    },

    /**
     * Retorna o valor do jwtToken do localStorage
     * @return {string}
     */
    jwtToken() {
      return localStorage.getItem("jwtToken");
    },

    /**
     * Retorna a URL referente ao valor recebida
     *
     * @param {string} value é o valor referente ao link da página
     * @return {string} link
     */
    legacyUrls(value) {
      let legacyUrlsList = {
        "store-bonus": `${this.store_info.uri.https}/loja/central_bonus.php?loja=${this.store_info.id}`,
        "related-clients": `${this.store_info.uri.https}/mvc/store/central/cliente_relacionado/cliente_relacionados/index?loja=${this.store_info.id}`,
        "sidebar-menu-lists": `${this.store_info.uri.https}/loja/central_listas.php?loja=${this.store_info.id}`,
        "shopping-list": `${this.store_info.uri.https}/mvc/store/central/lista_compra/lista_compra/index/loja:${this.store_info.id}`,
        "sidebar-menu-awarding": `${this.store_info.uri.https}/loja/central_premiacao.php?loja=${this.store_info.id}`,
        "sidebar-menu-refunds": `${this.store_info.uri.https}/loja/central_troca.php?loja=${this.store_info.id}`,
        "file-uploads": `${this.store_info.uri.https}/mvc/store/central/upload_pedido/upload/pedidos/loja:${this.store_info.id}`,
      };

      if (this.isLoginProduct) {
        return this.mountUrlHash(legacyUrlsList[value]);
      }

      return legacyUrlsList[value];
    },

    /**
     * Verifica se o id do plano informado existe no array de planos.
     * @param  {string}  planId
     * @return {Boolean}
     */
    hasPlan(planName) {
      const planId = this.plansList[planName];

      return this.store_info.plans.indexOf(`${planId}`) !== -1;
    },

    /**
     * Verifica se a lista de compras/orçamento estão com recursos habilitados.
     * @return {Boolean}
     */
    hasEnabledShoppingList() {
      const findRegistry = this.store_info.configuration?.data?.find(
        (element) =>
          (element.registry == "ativo_orcamento" && element.value == 1) ||
          (element.registry == "ativo_lista_compra" && element.value == 1)
      );

      return findRegistry?.value == 1;
    },
  },

  computed: {
    ...mapGetters(["langs"]),

    ...mapState({
      loading: (state) => (state.loading ? "app__loading" : null),
      store_info: (state) => state.store_info.list,
    }),
  },
};
</script>
<!-- eslint-disable -->
<template>
  <nav class="app__navigation">
    <!-- Close Icon Mobile -->
    <figure
      class="app__navigation__close-icon d-flex d-md-none"
      v-on:click="toggleMenu()"
    >
      <app-icon name="times" prefix="fa"></app-icon>
    </figure>

    <div class="app__navigation__wrapper">
      <router-link
        class="app__navigation__item app__link app__navigation__my-account"
        tag="a"
        :to="{ name: 'dashboard' }"
        exact
        :class="{ 'current-page': this.isActive('dashboard') }"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="user"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu["my-account"] }}
        </label>
      </router-link>

      <router-link
        class="app__navigation__item app__link app__navigation__my-orders"
        tag="a"
        :to="{ name: 'orders.list' }"
        :class="{ 'current-page': this.isActive('order') }"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="clipboard"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu["my-orders"] }}
        </label>
      </router-link>

      <router-link
        class="
          app__navigation__item
          app__link
          app__navigation__my-registration-information
        "
        tag="a"
        :to="{ name: 'profile.main' }"
        :class="{ 'current-page': this.isActive('profile') }"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="address-book"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu["my-information"] }}
        </label>
      </router-link>

      <router-link
        class="
          app__navigation__item
          app__link
          app__navigation__my-registration-information
        "
        tag="a"
        :to="{ name: 'addresses.list' }"
        :class="{ 'current-page': this.isActive('address') }"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon prefix="fa" name="map-marked-alt"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu["my-addresses"] }}
        </label>
      </router-link>

      <!-- Recurso Extra | 162 - Programa de Fidelização -->
      <a
        class="app__navigation__item app__link app__navigation__bonus"
        :href="this.legacyUrls('store-bonus')"
        v-if="hasStoreSettingEnabled('store_bonus')"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="address-card"></app-icon>
        </figure>

        <label
          class="app__navigation__item__label"
          :class="loading"
          v-text="getStoreBonus()"
        ></label>
      </a>
    
      <router-link
        class="
          app__navigation__item
          app__link
          app__navigation__let-me-know
        "
        tag="a"
        v-if="hasStoreSettingEnabled('product_let_me_know')"
        :to="{ name: 'waiting-list' }"
        :class="{ 'current-page': this.isActive('waiting-list') }"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="clock"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu["waiting-list"] }}
        </label>
      </router-link>
      
      <!-- Recurso Extra | 2311 - Lista de Desejos -->
      <a
        class="app__navigation__item app__link app__navigation__gift-list"
        :href="this.legacyUrls('sidebar-menu-lists')"
        v-if="hasPlan('wish-list') && hasStoreSettingEnabled('gifts_list')"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="list-ul" prefix="fa"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu.lists }}
        </label>
      </a>

      <!-- Recurso Extra | 2340 - B2B - Lista de Compras e Orçamento -->
      <a
        class="app__navigation__item app__link app__navigation__wishlist"
        :href="this.legacyUrls('shopping-list')"
        v-if="hasEnabledShoppingList()"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="list-ul" prefix="fa"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu["shopping-list"] }}
        </label>
      </a>

      <!-- Recurso Extra | 250 - Fidelização através de pontos - bônus -->
      <a
        class="app__navigation__item app__link app__navigation__store-bonus"
        :href="this.legacyUrls('sidebar-menu-awarding')"
        v-if="hasStoreSettingEnabled('store_bonus')"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="gift" prefix="fa"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu.awarding }}
        </label>
      </a>

      <a
        class="app__navigation__item app__link app__navigation__exchanges"
        :href="this.legacyUrls('sidebar-menu-refunds')"
        v-if="hasStoreSettingEnabled('exchange_refund')"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="sync-alt" prefix="fa"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu.refunds }}
        </label>
      </a>

      <!-- Recurso Extra | 2399 - Upload de arquivos para pedido -->
      <a
        class="app__navigation__item app__link app__navigation__upload-files"
        :href="this.legacyUrls('file-uploads')"
        v-if="hasPlan('file-uploads') && hasStoreSettingEnabled('order_upload')"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="file-archive"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu["file-uploads"] }}
        </label>
      </a>

      <router-link
        class="
          app__navigation__item
          app__link
          app__navigation__my-registration-information
        "
        tag="a"
        :to="{ name: 'password-change' }"
        :class="{ 'current-page': this.isActive('password-change') }"
      >
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon prefix="fa" name="user-lock"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu["change-password"] }}
        </label>
      </router-link>

      <section class="app__navigation__item app__link" @click="logout()">
        <figure class="app__navigation__item__icon" :class="loading">
          <app-icon name="sign-out-alt" prefix="fa" :flip="true"></app-icon>
        </figure>

        <label class="app__navigation__item__label" :class="loading">
          {{ this.langs.sidebar_menu.exit }}
        </label>
      </section>
    </div>
  </nav>
</template>
