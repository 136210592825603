import jwtDecode from "jwt-decode";

require("tray-pageview/build/tray-pageview.min");

class PageView {
  constructor() {
    this.ignorePages = [];
    this.lastPage = null;
    this.ready = false;
  }

  /**
   * Inicializador do Page View.
   *
   * @return void
   * */
  init(storeId) {
    // Solução paliativa para evitar erros do pageview durante os testes e2e no ambiente master.
    // eslint-disable-next-line radix
    if (parseInt(storeId) === 674536) {
      return;
    }
    // O page view requer um script com o seletor #pageview-script adicionado a página.
    let script = document.getElementById("pageview-script");
    if (!script) {
      script = document.createElement("script");
      script.setAttribute("id", "pageview-script");
      document.body.appendChild(script);
    }
    script.setAttribute("data-store", storeId);
    this.ready = true;
  }

  /**
   * Retorna a sessão do usuário autenticado.
   *
   * @private
   * @return {string|null}
   * */
  static getSessionId() {
    let session = null;
    try {
      const decodedToken = jwtDecode(localStorage.getItem("jwtToken")) || {};
      session = decodedToken.session || null;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return session;
  }

  /**
   * Verifica se a página fornecida deve ser ignorada.
   *
   * @param {string} pageName
   * @return boolean
   * */
  ignore(pageName) {
    return (
      pageName &&
      (this.lastPage === pageName || this.ignorePages.includes(pageName))
    );
  }

  /**
   * Envia os dados de navegação fornecidos.
   *
   * @param {string|null} pageName
   * @param {object} options
   * @return {void}
   * */
  send(pageName = null, options = {}) {
    const hostname = window.location.hostname;
    const isProd =
      ["production-tmk", "production-lw"].includes(process.env.APP_ENV) &&
      hostname.indexOf("-tmk.") === -1;

    if (!isProd || !this.ready || !pageName || this.ignore(pageName)) {
      return;
    }

    const sessionId = PageView.getSessionId();
    if (!sessionId) {
      // eslint-disable-next-line no-console
      throw console.error("Failed to track the page view: Session not found");
    }

    this.lastPage = pageName;
    const data = { ...options, visita: sessionId };

    window.tray.pageview.send(data);
  }
}

export default new PageView();
