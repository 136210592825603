// https://vuex.vuejs.org/en/mutations.html
/* eslint-disable no-param-reassign */
import OrderModel from "./models/order";
import PaymentMethodMessage from "./models/payment-method-message";

export default {
  // Orders
  setOrdersData(st, obj) {
    if (obj.list) {
      st.orders.list = obj.list;
    }
    if (obj.pagination) {
      st.orders.pagination = obj.pagination;
    }
  },

  // Order
  setOrderData(st, obj) {
    if (obj.list) {
      st.order.list = obj.list;
      st.order.model = new OrderModel(obj.list);
    }
  },

  /**
   * Define os produtos em cada pedido da lista
   * @param {object} state
   * @param {object} object
   */
  setOrdersProducts(state, object) {
    state.orders.list.forEach((order) => {
      if (order.id === object.order && object.isOpen !== undefined) {
        order.isOpen = object.isOpen;
      }

      if (order.id === object.order && object.urls) {
        order.urls = object.urls;
      }

      if (order.products && order.products.length) {
        return;
      }

      if (order.id === object.order) {
        order.products = object.products;
        order.paymentMethod.type = object.paymentMethod?.type;
      }
    });
  },

  /**
   * Define os dados de métodos de pagamento customizados
   * @param {object} state
   * @param {object} object
   */
  setPaymentMethodMessage(state, object) {
    state.orders.list.forEach((order) => {
      if (order.id === object.order) {
        order.payment_method_message = new PaymentMethodMessage(
          object.paymentMethodMessage
        );
      }
    });
  },

  setShippingExternalTracker(st, url) {
    st.order.externalShippingTracker = url;
  },

  setOrdersLoading(st, obj) {
    st.orders.loading = obj.loading;
  },

  setOrderLoading(st, obj) {
    st.order.loading = obj.loading;
  },

  setMainLoading(st, obj) {
    st.loading = obj.loading;
  },

  // Timeline
  setOrderTimeline(st, obj) {
    st.order.timeline = obj;
  },

  setTrackerTimeline(st, obj) {
    st.order.trackerTimeline = obj;
  },

  setTimelineLoading(st, obj) {
    st.order.timelineLoading = obj.loading;
  },

  setMessage(state, message) {
    state.order.messages.push(message);
  },

  clearMessages(state) {
    state.order.messages = [];
  },

  setProductRating(state, obj) {
    if (!obj.ProductComment) {
      obj.ProductComment = {
        rating: "0",
        comment: "",
      };
    }

    state.order.productRating = obj;
  },

  setProductRatingStars(state, value) {
    state.order.productRating.ProductComment.rating = value;
  },

  setProductRatingComment(state, value) {
    state.order.productRating.ProductComment.comment = value;
  },

  deleteProductRating(state) {
    state.order.productRating.ProductComment.rating = "";
    state.order.productRating.ProductComment.comment = "";
  },
};
