export default {
  setListData(st, obj) {
    st.waitingList.list = obj.list;
  },

  setMessages(st, message) {
    st.waitingList.messages.push(message);
  },

  clearMessages(st) {
    st.waitingList.messages.length = 0;
  },

  setLoading(st, { loading }) {
    st.waitingList.loading = loading;
  },
};
