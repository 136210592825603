<script>
import AppCreateStyle from "./src/CreateStyle";
import Styles from "./styles";

export default {
  name: "AppCustomizer",
  components: {
    AppCreateStyle,
  },

  props: {
    styles: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  mounted() {
    this.loadWebFont(this.webFont);
    this.customChromeTab(this.mergedStyles.header_background_color);
  },

  computed: {
    /**
     * Mescla o estilo fornecido nas props com o estilo padrão.
     * Caso o estilo das props não possua valor, ele não é utilizado.
     *
     * @return {object}
     * */
    mergedStyles() {
      const mergedStyles = { ...Styles };

      Object.keys(this.styles).forEach((key) => {
        if (this.styles[key]) {
          mergedStyles[key] = this.styles[key];
        }
      });

      return mergedStyles;
    },

    /**
     * Fonte selecionada
     * @returns {string}
     */
    webFont() {
      return this.mergedStyles.font_family;
    },

    /**
     * Cor do cabeçalho
     * @return {string} #8f8f8f
     */
    headerColor() {
      let color = this.mergedStyles.header_color;

      // A cor padrão do easy é muito clara para ser exibida
      if (this.isEasyHeaderDefault) {
        color = "#8f8f8f"; // Cor padrão definida no cabeçalho da central
      }

      return color;
    },

    /**
     * Verifica se as cores do cabeçalho
     * são as padrão do easyCheckout
     * @return {boolean}
     */
    isEasyHeaderDefault() {
      const background = this.mergedStyles.header_background_color;
      const color = this.mergedStyles.header_color;

      if (background === "#f5f5f5" && color === "#c3c3c3") {
        return true;
      }

      return false;
    },
  },

  methods: {
    /**
     * Monta o elemento <link> para carregar a fonte personalizada
     * @param {string} fontFamily
     * @return {boolean}
     */
    loadWebFont(fontFamily) {
      const loadedFonts = ["lato", "arial"];
      const isLoadedFont = loadedFonts.indexOf(fontFamily.toLowerCase()) !== -1;

      if (!fontFamily || isLoadedFont) {
        return;
      }

      const fontWithoutSpace = fontFamily.replace(/\s|_|-/g, "+");
      const fontCapitalized = fontWithoutSpace.replace(/\b(\w)/g, (first) =>
        first.toUpperCase()
      );
      const webFontUrl = `https://fonts.googleapis.com/css?family=${fontCapitalized}:400,700`;
      const webFontLink = document.createElement("link");

      webFontLink.setAttribute("href", webFontUrl);
      webFontLink.setAttribute("rel", "stylesheet");

      document.head.appendChild(webFontLink);
    },

    /**
     * Deixa a cor mais clara ou escura
     * ref: https://gist.github.com/renancouto/4675192
     * @param {string} color
     * @param {number} percent porcentagem para clarear a cor, use valores negativos para escurecer
     * @return {string} hexadecimal
     */
    lightenColor(color, percent) {
      if (typeof color !== "string") {
        return "transparent";
      }

      const clearColor = color[0] === "#" ? color.substr(1) : color;

      const num = parseInt(clearColor, 16);
      const amt = Math.round(2.55 * percent);
      const R = (num >> 16) + amt; // eslint-disable-line
      const B = ((num >> 8) & 0x00ff) + amt; // eslint-disable-line
      const G = (num & 0x0000ff) + amt; // eslint-disable-line

      const newColor = (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1); // eslint-disable-line

      return newColor;
    },

    /**
     * Altera a cor da aba no navegador google-chrome
     * @param {string} themeColor cor primaria do tema
     */
    customChromeTab(themeColor) {
      const chromeMetaTag = document.querySelector('meta[name="theme-color"]');

      if (!themeColor || !chromeMetaTag) {
        return;
      }

      chromeMetaTag.setAttribute("content", themeColor);
    },
  },
};
</script>
<template v-slot:default>
  <app-create-style>
    .app { font-family: {{ mergedStyles.font_family }}, sans-serif; color:
    {{ mergedStyles.text_color }}; } .app__link, .app__icon { color:
    {{ mergedStyles.text_color }}; } .app__link:hover { color: #{{
      lightenColor(mergedStyles.text_color, -30)
    }}; }

    <!-- Botões primários -->
    .tray-btn-primary, .app__button--primary:not(.app__loading), .app__login
    #tray-login-component .tray-btn-primary { background:
    {{ mergedStyles.button_primary_background }} !important; border-color:
    {{ mergedStyles.button_primary_border }} !important; color:
    {{ mergedStyles.button_primary_text }} !important; }
    .app__button--primary-outline:not(.app__loading) { border-color:
    {{ mergedStyles.button_primary_background }}; color:
    {{ mergedStyles.button_primary_background }}; }
    .app__button--primary-outline:not(.app__loading) .app__icon { color:
    {{ mergedStyles.button_primary_background }}; } .app__button--primary:hover,
    .app__button--primary-outline:hover, .app__login #tray-login-component
    .tray-btn-primary:hover { background:
    {{ mergedStyles.button_primary_background_hover }} !important; border-color:
    {{ mergedStyles.button_primary_border_hover }} !important; color:
    {{ mergedStyles.button_primary_text_hover }} !important; } .app__button
    .app__icon { color: {{ mergedStyles.button_primary_text }}; }
    .app__button:hover .app__icon { color:
    {{ mergedStyles.button_primary_text_hover }}; }

    <!-- Botões secundários -->
    .app__button--secondary:not(.app__loading) { background:
    {{ mergedStyles.button_secondary_background }}; border-color:
    {{ mergedStyles.button_secondary_border }}; color:
    {{ mergedStyles.button_secondary_text }}; }
    .app__button--secondary-outline:not(.app__loading) { border-color:
    {{ mergedStyles.button_secondary_background }}; color:
    {{ mergedStyles.button_secondary_background }}; }
    .app__button--secondary:hover, .app__button--secondary-outline:hover {
    background: {{ mergedStyles.button_secondary_background_hover }};
    border-color: {{ mergedStyles.button_secondary_border_hover }}; color:
    {{ mergedStyles.button_secondary_text_hover }}; }

    <!-- Header -->
    .app__header { background: {{ mergedStyles.header_background_color }};
    border-color: {{ mergedStyles.header_border_color }}; color:
    {{ headerColor }}; } .app__header .app__icon { color: {{ headerColor }}; }

    <!-- Navegação -->
    .app__navigation__item__label { color: {{ mergedStyles.text_color }}; }
    .app__navigation__item:hover, .app__navigation__item:hover
    .app__navigation__item__icon, .app__navigation__item.active-page
    .app__navigation__item__icon { background:
    {{ mergedStyles.header_background_color }}; border-color: #{{
      lightenColor(mergedStyles.header_background_color, -30)
    }}; } .app__navigation__item:hover .app__navigation__item__label,
    .app__navigation__item:hover .app__navigation__item__icon svg,
    .app__navigation__item.active-page .app__navigation__item__icon svg { color:
    {{ headerColor }}; } @media (max-width: 768px) { .app__navigation {
    background: {{ mergedStyles.header_background_color }}; }
    .app__navigation__item { background: #{{
      lightenColor(mergedStyles.header_background_color, -10)
    }}; border-color: #{{
      lightenColor(mergedStyles.header_background_color, -30)
    }}; } .app__navigation__item:hover, .app__navigation__item:hover
    .app__navigation__item__icon, .app__navigation__item.active-page
    .app__navigation__item__icon { background: #{{
      lightenColor(mergedStyles.header_background_color, -30)
    }}; border-color: #{{
      lightenColor(mergedStyles.header_background_color, -10)
    }}; } .app__navigation__close-icon { background: #{{
      lightenColor(mergedStyles.header_background_color, -10)
    }}; border-color: #{{
      lightenColor(mergedStyles.header_background_color, -30)
    }}; } .app__navigation__close-icon svg { color: {{ headerColor }} }
    .app__navigation__item.active-page svg { color: {{ headerColor }} }
    .app__navigation__item__label { color: {{ headerColor }}; }
    .app__navigation__item__icon { background:
    {{ mergedStyles.header_background_color }}; } .app__navigation__item__icon
    svg { color: {{ headerColor }}; } }

    <!-- Timeline -->
    .app__timeline__step__date--brighter { color: #{{
      lightenColor(mergedStyles.text_color, 20)
    }}
    }
    <!-- Footer -->
    :root { --footer-color: {{ mergedStyles.footer_color }}; --footer-bg:
    {{ mergedStyles.footer_background_color }}; --footer-bd-color:
    {{ mergedStyles.footer_border_color }}; --footer-safe-color:
    {{ mergedStyles.footer_safe_color }}; }
  </app-create-style>
</template>
