export default {
  setFirstAccess(state, obj) {
    state.isFirstAccess = obj.isFirstAccess;
  },

  setLoading(state, obj) {
    state.loading = obj.loading;
  },

  setMessage(state, obj) {
    state.messages[obj.type] = obj.message;
  },

  setStoreInfo(state, obj) {
    if (obj.list) {
      state.store_info.list = { ...state.store_info.list, ...obj.list };
    }

    if (obj.full_list) {
      state.store_info.full_list = {
        ...state.store_info.list,
        ...obj.full_list,
      };
    }
  },

  setGeneralError(state, obj) {
    state.general_error = obj;

    if (obj.message) {
      state.messages.error.push(obj.message);
    }
  },

  setIsMobile(state, windowSize) {
    state.is_mobile = windowSize <= 768;
  },

  /**
   * Define as propriedades da personalização
   * @param {object} state
   * @param {object} styles
   */
  setCustomStyle(
    state,
    {
      font_family = "",
      text_color = "",
      link_color = "",
      header = {
        background: "",
        border: "",
        color: "",
      },
      footer = {
        background: "",
        border: "",
        color: "",
      },
    } = {}
  ) {
    state.store_info.list.styles = {
      font_family,
      text_color,
      link_color,
      header,
      footer,
    };
  },

  /**
   * Define as traduções
   * @param {object} state
   * @param {object} object
   */
  setLangs(state, object) {
    if (object.list) {
      state.store_info.list.langs = object.list;
    }
  },

  /**
   * Altera os dados do callbackpost
   * @param {object} state
   * @param {object} object
   */
  setDataCallbackPost(state, object) {
    if (object.list) {
      const newDataCallbackPost = state.store_info.list.dataCallbackPost;

      Object.keys(object.list).forEach((key) => {
        if (Object.keys(newDataCallbackPost).includes(key)) {
          newDataCallbackPost[key] = object.list[key];
        }
      });

      state.store_info.list.dataCallbackPost = newDataCallbackPost;
    }
  },

  /**
   * Altera o status de autenticação do usuário
   * @param {object} state
   * @param {object} isAuthenticated
   */
  setIsUserAuthenticated(state, isAuthenticated) {
    state.isUserAuthenticated = isAuthenticated;
  },
};
