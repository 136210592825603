// https://vuex.vuejs.org/en/state.html

export default {
  loading: true,
  isFirstAccess: false,
  is_mobile: true,
  general_error: false,
  isUserAuthenticated: true,
  messages: {
    success: "",
    error: [],
    warning: "",
    validation: [],
  },
  store_info: {
    list: {
      id: "",
      status: "",
      plan: "",
      name: "",
      logo: {
        desktop: {
          https: "",
        },
        mobile: {
          https: "",
        },
      },
      company: {
        name: "",
        cnpj: "",
      },
      features: [],
      shipment_trackers: [],
      email: "",
      phone: "",
      address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
      },
      uri: {
        https: "",
      },
      integrations: {
        google: {
          analytics: "",
          tagmanager: "",
        },
        chat: "",
      },
      texts: {
        footer: "",
      },
      plans: [],
      settings: {
        store: {
          bonus_name: "",
          change_profile: "",
          international_shipping: "",
        },
      },
      styles: {},
      terms: "",
      customer: {
        id: "",
        type: "",
        name: "",
        email: "",
        cpf: "",
        gravatar: "",
        birth_date: "",
        phone: "",
        mobile_phone: "",
        is_foreign: false,
        company: {
          name: "",
          cnpj: "",
          state_inscription: "",
        },
        profile: {
          name: "",
        },
      },
      customer_attributes: [],
      langs: {
        summary: {},
        profile: {},
        sidebar_menu: {},
        shipping: {},
        store: {},
        product: {},
        pagination: {},
        order: {},
        addresses: {},
        password_change: {},
        login: {},
        payment_confirmation: {},
      },
      dataCallbackPost: {
        loja: "",
        frete: "",
        token: "",
        IdProd: "",
        transID: "",
        parceiro: "",
        PHPSESSID: "",
        id_evento: "",
        premiacao: "",
        id_pedido: "",
        redirecionar: "",
        login: "1",
        social: "0",
        origem: "central",
      },
    },
  },
};
