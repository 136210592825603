<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "AppIcon",

  components: {
    FontAwesomeIcon,
  },

  props: {
    name: {
      type: String,
      default: "user",
    },

    /**
     * Font awesome style
     * @fa solid
     * @far regular
     */
    prefix: {
      type: String,
      default: "far",
    },

    flip: Boolean,
  },
};
</script>

<template>
  <i class="app__icon" :class="{ 'app__icon--flip': flip }">
    <font-awesome-icon :icon="[prefix, name]" class="app__icon" />
  </i>
</template>
