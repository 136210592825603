import BaseModel from "@/store/models/base";

export default class GiftWrapModel extends BaseModel {
  /**
   * {@inheritDoc}
   * */
  getSchema() {
    return {
      id: "",
      image: "",
      name: "",
      price: "",
      product_id: null,
    };
  }

  /**
   * Retorna o valor do embalagem.
   *
   * @return {number}
   * */
  get price() {
    return +this.rawAttributes.price || 0;
  }
}
