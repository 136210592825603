<script>
export default {
  name: "AppSvg",

  data() {
    return {
      icon: "",
    };
  },

  props: {
    /**
     * Nome do ícone.
     * @var {string} name
     */
    name: {
      type: String,
    },
  },

  mounted() {
    this.loadIcon();
  },

  methods: {
    /**
     * Carrega o ícone com o nome fornecido.
     *
     * @return {void}
     * */
    loadIcon() {
      if (!this.name) {
        this.icon = "";
        return;
      }
      const promise = import(
        /* webpackChunkName: "icon [request]" */ `@/assets/icons/svg/${this.name}.svg`
      );
      promise.then((icon) => {
        this.icon = icon.default;
      });
    },
  },

  watch: {
    name() {
      this.loadIcon();
    },
  },
};
</script>

<template>
  <i class="app__icon" v-if="icon" v-html="icon"></i>
</template>
