/**
 * Dispara a ação para limpar o endereço atual
 */
export const clearAddress = ({ commit }) => {
  commit("clearAddress");
};

/**
 * Dispara a ação para limpar as mensagens
 * @param {function} commit
 */
export const clearMessages = ({ commit }) => {
  commit("clearMessages");
};

/**
 * Dispara a ação para definir um erro de backend
 * @param {function} obj
 * @param {object} errors objeto com os erros
 */
export const setBackendErrors = ({ commit }, errors) => {
  commit("setBackendErrors", errors);
};

/**
 * Dispara a ção para limpar os erros do backend
 * @param {function} commit
 */
export const clearBackendErrors = ({ commit }) => {
  commit("clearBackendErrors");
};

/**
 * Dispara a ação para remover um endereço da listagem
 * @param {function} commit
 * @param {string} addressId
 */
export const deleteAddress = ({ commit }, addressId) => {
  commit("deleteAddress", addressId);
};

/**
 * Dispara a ação para definir os endereços
 * @param {function} commit
 * @param {object} addresses
 */
export const setAddresses = ({ commit }, addresses) => {
  commit("setAddresses", addresses);
};

/**
 * Dispara a ação para definir um endereço
 * @param {function} commit
 * @param {object} address
 */
export const setAddress = ({ commit }, address) => {
  commit("setAddress", address);
};

/**
 * Dispara a ação para definir uma mensagem
 * @param {function} commit
 * @param {object} obj
 */
export const setMessage = ({ commit }, obj) => {
  commit("setMessage", obj);
};
