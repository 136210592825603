import BaseModel from "@/store/models/base";

export default class ShippingModel extends BaseModel {
  /**
   * @inheritDoc
   * */
  getSchema() {
    return {
      code: "#####",
      tracking_url: "",
      estimated_date: "",
      date: "",
      is_traceable: false,
    };
  }

  /**
   * Realiza o tratamento do código de rastreio.
   *
   * @return {string}
   */
  getTrackingCode() {
    const isHtml = /<[a-z][\s\S]*>/i.test(this.code);
    if (isHtml) {
      return this.code;
    }

    try {
      new URL(this.code); // eslint-disable-line no-new
      return "";
    } catch (e) {
      return this.code;
    }
  }

  /**
   * Realiza o tratamento do código de rastreio
   * @return {string}
   */
  isValidTrackingUrl() {
    if (!this.tracking_url) {
      return "";
    }

    const isValid =
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
        this.tracking_url
      );

    return isValid ? this.tracking_url : "";
  }

  /**
   * Verifica se o parâmetro enviado é uma URL
   * @param {string}
   * @return {bool}
   */
  isUrl(param) {
    let url;

    try {
      url = new URL(param);
    } catch (e) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
}
