import { CancelToken } from "axios";

const agents = [
  "Googlebot",
  "Googlebot-Image",
  "Googlebot-News",
  "Googlebot-Video",
  "Storebot-Google",
  "Google-InspectionTool",
  "GoogleOther",
  "Google-Extended",
  "APIs-Google",
  "Mediapartners-Google",
  "FeedFetcher-Google",
  "AdsBot-Google-Mobile",
  "GoogleProducer",
  "Google-Site-Verification",
  "Applebot",
  "bingbot",
  "Pingdom",
  "pingbot",
  "AhrefsSiteAudit",
  "Google-Safety",
  "SeekportBot",
  "BitSightBot",
  "SiteAuditBot",
  "SemrushBot-BA",
  "SemrushBot-SI",
  "SemrushBot-SWA",
  "SemrushBot-CT",
  "SplitSignalBot",
  "SemrushBot-COUB",
  "PetalBot",
  "ClarityBot",
  "Jooblebot",
  "Pinterestbot",
  "adidxbot",
  "BingPreview",
  "MicrosoftPreview",
  "Cincraw",
  "AdsBot-Google",
  "PagePeeker",
  "Taboolabot",
  "SiteCheckerBotCrawler",
  "Monsidobot",
  "Seobility",
  "SeobilityBot",
  "Slackbot-LinkExpanding",
  "Slack-ImgProxy",
  "Slackbot",
  "SiteScoreBot",
  "facebookexternalhit",
  "facebookcatalog",
  "PlayStore-Google",
].map((userAgent) => userAgent.toLocaleLowerCase());

const cancelToken = new CancelToken((cancel) => {
  const userAgent = window.navigator.userAgent.toLocaleLowerCase();
  const robot = agents.find((agent) => userAgent.includes(agent));
  if (robot) {
    cancel();
  }
});

const botInterceptor = (config) => ({
  ...config,
  cancelToken,
});

export default botInterceptor;
