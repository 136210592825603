import { routes as Dashboard } from "./Dashboard";
import { routes as Orders } from "./Orders";
import { routes as Customer } from "./Customer";
import { routes as Address } from "./Address";
import { routes as WaitingList } from "./WaitingList";
import { routes as Login } from "./Login";

// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Spread_operator
// Assim, uma nova matriz é criada, contendo todos os objetos que correspondem às rotas.
// Dashboard fica por ultimo devido a instrução catchall
export default [
  ...Orders,
  ...Customer,
  ...Address,
  ...WaitingList,
  ...Login,
  ...Dashboard,
];
