import {
  faAddressBook,
  faAddressCard,
  faBuilding,
  faEnvelope,
  faCalendar,
  faClipboard,
  faClock,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faIdCard,
  faMap,
  faMoneyBillAlt,
  faUserCircle,
  faStar,
  faUser,
} from "@fortawesome/free-regular-svg-icons";

export default {
  faAddressBook,
  faAddressCard,
  faBuilding,
  faEnvelope,
  faCalendar,
  faClipboard,
  faClock,
  faEye,
  faEyeSlash,
  faFileArchive,
  faIdCard,
  faFile,
  faFileAlt,
  faMap,
  faMoneyBillAlt,
  faUserCircle,
  faStar,
  faUser,
};
