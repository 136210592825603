import BaseModel from "@/store/models/base";

export default class CalculatorModel extends BaseModel {
  /**
   * @inheritDoc
   * */
  getSchema() {
    return {
      coupon: "",
      payment: "",
      taxes: "",
      products: "",
      shipping: "",
      total: "",
    };
  }
}
