/**
 * Component is lazy-loaded
 * http://router.vuejs.org/en/advanced/lazy-loading.html
 */

export default [
  {
    name: "profile",
    path: "/profile",
    meta: {
      title: "Meus dados",
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "customer-custom-page" */ "../../Orders/components/main"
      ),
    children: [
      {
        name: "profile.main",
        path: "",
        component: () =>
          import(/* webpackChunkName: "customer-page" */ "../components/main"),
      },
      {
        name: "profile.purchase",
        path: "purchase-profile",
        meta: {
          title: "Meus dados",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer-page" */ "../components/edit/purchase-profile"
          ),
      },
    ],
  },
  {
    name: "password-change",
    path: "/password-change",
    meta: {
      title: "Alterar senha",
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "customer-page" */ "../components/edit/password-change"
      ),
  },
];
