import capitalize from "lodash/capitalize";
import deburr from "lodash/deburr";
import BaseModel from "@/store/models/base";

export default class PaymentMethodModel extends BaseModel {
  /**
   * @inheritDoc
   * */
  getSchema() {
    return {
      id: "",
      name: "",
      installment: 1,
      type: "",
    };
  }

  /**
   * Retorna o nome do método de pagamento fornecido.
   *
   * @return {string}
   * */
  get name() {
    return this.rawAttributes.name;
  }

  /**
   * Retorna o número de parcelas do pedido.
   *
   * @return {number}
   * */
  get installment() {
    return +this.rawAttributes.installment || 1;
  }

  /**
   * Utiliza-se o nome do pagamento como código.
   *
   * @return {string}
   * */
  get code() {
    return deburr(this.name).toLowerCase();
  }

  /**
   * Determina se o meio de pagamento é do tipo boleto.
   *
   * @return {boolean}
   * */
  isBillet() {
    return this.type === "bank_billet";
  }

  /**
   * Determina se o meio de pagamento é do tipo pix.
   *
   * @return {boolean}
   * */
  isPix() {
    return this.type === "pix";
  }

  /**
   * Determina se o meio de pagamento é cartão de crédito.
   *
   * @return {boolean}
   * */
  isCreditCard() {
    return this.type === "credit_card";
  }

  /**
   * Determina se o meio de pagamento é cartão de débito.
   *
   * @return {boolean}
   * */
  isDebitCard() {
    return this.type === "debit_card";
  }

  /**
   * Determina se o meio de pagamento é cartão.
   *
   * @return {boolean}
   * */
  isCard() {
    return this.isDebitCard() || this.isCreditCard();
  }

  /**
   * Determina se o meio de pagamento é do tipo depósito.
   *
   * @return {boolean}
   * */
  isDeposit() {
    return this.code.indexOf("deposito") > -1;
  }

  /**
   * Determina se o meio de pagamento é do tipo transferência.
   *
   * @return {boolean}
   * */
  isTransfer() {
    return this.type === "wire_transfer";
  }

  /**
   * Determina se é um meio de pagamento intermediário.
   *
   * @return {boolean}
   * */
  isIntermediary() {
    return ["pagseguro", "paypal", "moip", "mercadopago", "bcash"].some(
      (type) => this.type === type
    );
  }

  /**
   * Retorna o nome do intermediador de pagamento.
   *
   * @return {string}
   * */
  getIntermediaryBrandName() {
    const intermediaries = {
      pagseguro: "PagSeguro",
      paypal: "PayPal",
      moip: "MoiP",
      mercadopago: "Mercado Pago",
      bcash: "Bcash",
    };

    return intermediaries[this.type] || capitalize(this.name);
  }
}
