/**
 * Font Awesome v.5
 *
 * Ícones disponíveis
 * https://fontawesome.com/icons?d=gallery&s=regular,solid&m=free
 */
import solidFonts from "./solid";
import regularFonts from "./regular";
import brandFonts from "./brands";

export default {
  ...solidFonts,
  ...regularFonts,
  ...brandFonts,
};
