<script>
import loginProduct from "@/mixins/loginProduct";
export default {
  name: "AppStoreLogo",

  mixins: [loginProduct],

  props: {
    list: {
      type: Object,
      default() {
        return {
          name: "Tray",
          logo: {
            desktop: {
              https: "",
            },
            mobile: {
              https: "",
            },
          },
          uri: {
            https: "",
          },
        };
      },
    },
    loading: Boolean,
  },

  watch: {
    storeLogo(urlLogo) {
      if (!urlLogo) {
        return;
      }
    },
  },

  computed: {
    /**
     * Verifica e retorna a logo cadastrada na loja
     * @returns {String|Boolean} Url da logo caso exista, false caso não
     */
    storeLogo() {
      const mobile = this.list.logo.mobile.https;
      const desktop = this.list.logo.desktop.https;

      if (!mobile && !desktop) {
        return false;
      }

      if (this.isMobile()) {
        return mobile || desktop;
      }

      return desktop || mobile;
    },
  },

  methods: {
    /**
     * Verifica se o usuario está navegando em um dispositivo móvel
     * @return {Boolean} isMobile
     */
    isMobile() {
      return window.innerWidth <= 768;
    },

    /**
     * Nome da Loja
     * @return {String} name
     */
    storeName() {
      return this.list.name;
    },

    /**
     * Url da loja
     * @return {String} url
     */
    storeUrl() {
      if (this.isLoginProduct) {
        return this.mountUrlHash(this.list.uri.https);
      }

      return this.list.uri.https;
    },
  },
};
</script>

<template>
  <div class="app__header__container-logo" :class="{ app__loading: loading }">
    <a :href="storeUrl()" :title="storeName()" class="app__header__store-link">
      <h1 v-show="!storeLogo" class="app__header__title">
        {{ storeName() }}
      </h1>
      <figure class="app__header__logo-wrapper" v-if="storeLogo">
        <img class="app__header__logo" :src="storeLogo" :alt="storeName()" />
      </figure>
    </a>
  </div>
</template>
