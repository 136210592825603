<script>
import { h } from "vue";

export default {
  name: "AppCreateStyle",

  render() {
    return h(
      "style",
      {
        type: "text/css",
      },
      this.$slots.default()
    );
  },
};
</script>
