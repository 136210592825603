export const setData = ({ commit }, obj) => {
  commit("setData", obj);
};

export const setCustomerAttribute = ({ commit }, obj) => {
  commit("setCustomerAttribute", obj);
};

export const setLoading = ({ commit }, obj) => {
  commit("setLoading", obj);
};

export const setMessage = ({ commit }, obj) => {
  commit("setMessage", obj);
};

export const clearMessages = ({ commit }) => {
  commit("clearMessages");
};

export const setIsPersonalDataInvalid = ({ commit }, obj) => {
  commit("setIsPersonalDataInvalid", obj);
};

export const setIsBusinessDataInvalid = ({ commit }, obj) => {
  commit("setIsBusinessDataInvalid", obj);
};

export const setIsBusinessAttributeDataInvalid = ({ commit }, obj) => {
  commit("setIsBusinessAttributeDataInvalid", obj);
};

export const setIsPersonalAttributeDataInvalid = ({ commit }, obj) => {
  commit("setIsPersonalAttributeDataInvalid", obj);
};

export const setIsBothAttributeDataInvalid = ({ commit }, obj) => {
  commit("setIsBothAttributeDataInvalid", obj);
};

export const setBackendErrors = ({ commit }, obj) => {
  commit("setBackendErrors", obj);
};

export const setVuelidateErrors = ({ commit }, obj) => {
  commit("setVuelidateErrors", obj);
};

export const setSelectedProfile = ({ commit }, obj) => {
  commit("setSelectedProfile", obj);
};

export const setProfiles = ({ commit }, obj) => {
  commit("setProfiles", obj);
};

export const clearBackendErrors = ({ commit }) => {
  commit("clearBackendErrors");
};
