<script>
import { mapState, mapGetters } from "vuex";
import SeloLojaProtegida from "../../assets/loja-protegida.png";
import AppSecureSeal from "./SecureSeal.vue";

export default {
  name: "AppStoreSecurity",
  components: {
    AppSecureSeal,
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),
    ...mapGetters(["langs"]),

    /**
     * Retorna os dados de integração da loja.
     *
     * @return {object}
     * */
    integrations() {
      return this.store_info.integrations || {};
    },

    /**
     * Retorna o código da loja junto ao ebit.
     *
     * @see https://atendimento.tray.com.br/hc/pt-br/articles/211174798-E-bit-Integra%C3%A7%C3%A3o
     * @return {string|null}
     * */
    ebitCode() {
      if (!this.integrations.ebit || !this.integrations.ebit.code) {
        return null;
      }

      const ebitCode = this.integrations.ebit.code;
      const delimiterStart = 1;
      const delimiterEnd = ebitCode.length - 1;

      return ebitCode.substring(delimiterStart, delimiterEnd);
    },

    /**
     * Retorna a url da integração da loja com o Loja Protegida.
     *
     * @return {string|undefined}
     * */
    lojaProtegidaLink() {
      return this.integrations.lojaprotegida;
    },

    /**
     * Retorna a url do selo da loja protegida.
     *
     * @return {string}
     * */
    seloLojaProtegida() {
      return SeloLojaProtegida;
    },
  },
};
</script>

<template>
  <div class="app__footer__security" :class="{ app__loading: this.loading }">
    <div v-if="!!ebitCode" class="app__footer__security__block">
      <a
        id="seloEbit"
        target="_blank"
        :href="`http://www.ebit.com.br/${ebitCode}/selo`"
        title="Avaliado pelos consumidores"
        data-noop="redir(this.href)"
        class="app__footer__security__ebit"
      >
        <img
          alt="Selo Ebit"
          :src="`https://newimgebit-a.akamaihd.net/ebitBR/selo/img_${ebitCode}.png`"
        />
      </a>
    </div>

    <div class="app__footer__security__block">
      <app-secure-seal :loading="loading" />
      <a
        v-if="!!lojaProtegidaLink"
        class="app__footer__security__loja-protegida"
        :href="lojaProtegidaLink"
        title="Loja Protegida"
        target="_blank"
      >
        <img :src="seloLojaProtegida" alt="Loja Protegida" />
      </a>
    </div>
  </div>
</template>
