/**
 * Component is lazy-loaded
 * http://router.vuejs.org/en/advanced/lazy-loading.html
 */

export default [
  {
    path: "/orders",
    component: () =>
      import(/* webpackChunkName: "order-list-page" */ "../components/main"),
    children: [
      {
        name: "orders.list",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "order-list-page" */ "../components/order-list"
          ),
        meta: {
          title: "Meus pedidos",
          requiresAuth: true,
        },
      },
      {
        name: "orders.detail",
        path: ":id",
        component: () =>
          import(/* webpackChunkName: "order-page" */ "../components/order"),
        meta: {
          title: `Resumo do pedido`,
          requiresAuth: true,
        },
      },
      {
        name: "orders.rating",
        path: "rating/:idOrder/:idComment",
        component: () =>
          import(
            /* webpackChunkName: "evaluation-page" */ "../components/product-rating/main"
          ),
        meta: {
          title: `Avaliação do pedido`,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: "orders.tracking",
    path: "/tracker/:storeId/:accessCode",
    component: () =>
      import(
        /* webpackChunkName: "tracking-page" */ "../components/tracking/main.vue"
      ),
    meta: {
      title: "Rastreio",
      requiresAuth: false,
    },
  },
];
