import BaseModel from "@/store/models/base";
import StatusModel from "./status";
import PaymentModel from "./payment";
import PaymentMethodModel from "./payment-method";
import PaymentMethodMessageModel from "./payment-method-message";
import CalculatorModel from "./calculator";
import ShippingModel from "./shipping";
import Extensions from "./extensions";

export default class OrderModel extends BaseModel {
  /**
   * @inheritDoc
   * */
  getSchema() {
    return {
      id: "",
      status: {},
      shipping: {},
      calculator: {},
      payment: null,
      payment_method: {
        type: "",
      },
      paymentMethodMessage: {},
      pickupLocation: {},
      products: [],
      urls: {
        code: "",
        payment: "",
        transaction: "",
      },
      extensions: {},
    };
  }

  /**
   * Tipo de pagamento pix
   * @return {string}
   */
  get pixType() {
    return "pix";
  }

  /**
   * Verifica se o pagamento foi efetuado via Pix
   * @return {string}
   */
  get isPix() {
    return this.payment_method.type === this.pixType;
  }

  /**
   * Retorna os dados do pagamento.
   *
   * @return {number}
   * */
  get id() {
    return this.rawAttributes.id;
  }

  /**
   * Retorna o total do pedido.
   *
   * @return {string}
   * */
  get total() {
    return this.rawAttributes.calculator.total;
  }

  /**
   * Retorna os dados do pagamento.
   *
   * @return {PaymentModel|null}
   * */
  get payment() {
    return this.rawAttributes.payment;
  }

  /**
   * Getter para o atributo status.
   *
   * @return {StatusModel}
   * */
  get status() {
    return this.rawAttributes.status;
  }

  /**
   * Getter para os produtos do pedido
   *
   * @return {Array}
   */
  get products() {
    return this.rawAttributes.products;
  }

  /**
   * Getter para o atributo shipping.
   *
   * @return {ShippingModel}
   * */
  get shipping() {
    return this.rawAttributes.shipping;
  }

  /**
   * Getter para o meio de pagamento do pedido.
   *
   * @return {PaymentMethodModel}
   * */
  get payment_method() {
    return this.rawAttributes.payment_method;
  }

  /**
   * Retorna as informações de formas de pagamento personalizadas
   * @return {PaymentMethodMessageModel}
   */
  get paymentMethodMessage() {
    return this.rawAttributes.paymentMethodMessage;
  }

  /**
   * Retorna a url de pagamento.
   *
   * @return {string}
   * */
  get paymentLink() {
    return this.urls.transaction;
  }

  /**
   * Retorna as extensões do pedido.
   *
   * @return {Extensions}
   * */
  get extensions() {
    return this.rawAttributes.extensions;
  }

  /**
   * Retorna as informações de retirada do pedido
   * @return {PickupLocationModel}
   */
  get pickupLocation() {
    return this.rawAttributes.pickupLocation;
  }

  /**
   * Valida se é um pedido com produtos virtuais
   * @return {bool}
   */
  get hasOnlyVirtualProducts() {
    return this.rawAttributes.onlyVirtualProducts;
  }

  /**
   * Modifica o objeto de status do pedido.
   *
   * @param {object} attributes
   * @private
   * @return {StatusModel}
   * */
  mutateStatus(attributes) {
    return new StatusModel(attributes);
  }

  /**
   * Modifica o objeto de shipping do pedido.
   *
   * @param {object} attributes
   * @private
   * @return {ShippingModel}
   * */
  mutateShipping(attributes) {
    return new ShippingModel(attributes);
  }

  /**
   * Modifica o objeto calculator do pedido.
   *
   * @param {object} attributes
   * @private
   * @return {CalculatorModel}
   * */
  mutateCalculator(attributes) {
    return new CalculatorModel(attributes);
  }

  /**
   * Modifica o objeto de pagamento do pedido.
   *
   * @param {object|null} attributes
   * @private
   * @return {PaymentModel}
   * */
  mutatePayment(attributes) {
    if (!attributes) {
      return null;
    }
    return new PaymentModel(attributes);
  }

  /**
   * Modifica o objeto com a forma de pagamento do pedido.
   *
   * @param {object} attributes
   * @private
   * @return {PaymentMethodModel}
   * */
  mutatePaymentMethod(attributes) {
    return new PaymentMethodModel(attributes);
  }

  /**
   * Modifica o objeto com a forma de pagamento do pedido.
   *
   * @param {object} attributes
   * @private
   * @return {PaymentMethodMessageModel}
   * */
  mutatePaymentMethodMessage(attributes) {
    return new PaymentMethodMessageModel(attributes);
  }

  /**
   * Transforma o objeto fornecido em uma instância de Extensions.
   *
   * @param {object} attributes
   * @private
   * @return {Extensions}
   * */
  mutateExtensions(attributes) {
    return new Extensions(attributes);
  }

  /**
   * Determina se o pedido possui pagamento.
   *
   * @return {boolean}
   * */
  hasPayment() {
    return !!this.payment;
  }
}
