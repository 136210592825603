<script>
import { logout as authLogout } from "@/plugins/auth";
import { mapGetters } from "vuex";

export default {
  name: "AppDropdown",
  props: {
    /**
     * Loading
     * @type {boolean}
     */
    loading: Boolean,
    storeInfo: {},
  },

  methods: {
    /**
     * Realiza o logout do usuário na nova central e redireciona para a url
     * de logout da central antiga.
     * @return {void}
     */
    logout() {
      authLogout();
      this.redirectToCommerceLogout();
    },

    /**
     * Redireciona para a url de logout da Plataforma Commerce.
     * @return {void}
     */
    redirectToCommerceLogout() {
      const storeId = this.storeInfo.id;
      const secureDomain = this.storeInfo.uri.https;

      window.location.replace(
        `${secureDomain}/loja/logout.php?loja=${storeId}`
      );
    },

    /**
     * Redireciona para a url de troca de senha da Plataforma Commerce.
     * @return {void}
     */
    goToChangePasswordPage() {
      window.location.replace(
        `${this.storeInfo.uri.https}/loja/central_senha.php?loja=${this.storeInfo.id}`
      );
    },
  },

  computed: {
    ...mapGetters(["langs"]),
  },
};
</script>

<template>
  <section class="app__dropdown">
    <ul class="app__dropdown__menu">
      <router-link
        class="app__dropdown__item"
        tag="li"
        :to="{ name: 'dashboard' }"
      >
        <a class="app__link">{{ this.langs.sidebar_menu["my-account"] }}</a>
      </router-link>
      <router-link
        class="app__dropdown__item"
        tag="li"
        :to="{ name: 'orders.list' }"
      >
        <a class="app__link">{{ this.langs.sidebar_menu["my-orders"] }}</a>
      </router-link>
      <router-link
        class="app__dropdown__item"
        tag="li"
        :to="{ name: 'password-change' }"
      >
        <a class="app__link">{{
          this.langs.sidebar_menu["change-password"]
        }}</a>
      </router-link>
      <li class="app__dropdown__item">
        <a href="#" class="app__link" @click="logout()">{{
          this.langs.sidebar_menu.exit
        }}</a>
      </li>
    </ul>
  </section>
</template>
