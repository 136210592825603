<script>
export default {
  name: "AppStoreCredits",
  props: {
    /**
     * Lista as informações da loja
     */
    list: {
      type: Object,
      default() {
        return {
          address: {
            street: "Alcides Lajes Magalhães",
            city: "Marília",
            state: "SP",
            zip_code: "17525-181",
          },
          company: {
            name: "Tray",
            cnpj: "9999-9999-9999-99999",
          },
          email: "teste@tray.com.br",
          phone: "(99) 9999-9999",
          texts: {
            footer: "Todos os direitos reservados",
          },
        };
      },
    },
    loading: Boolean,
  },

  methods: {
    /**
     * Nome da Loja
     * @return {string}
     */
    storeName() {
      return this.list.company.name;
    },

    /**
     * CNPJ da loja
     * @return {string} cnpj
     */
    storeDocument() {
      return this.list.company.cnpj;
    },

    /**
     * Endereço da loja
     * @return @return {string} street, address, city, zipCode
     */
    storeAddress() {
      const address = this.list.address;

      const street = address.street ? `${address.street}, ` : "";
      const city = address.city ? `${address.city}, ` : "";
      const state = address.state ? `${address.state}, ` : "";
      const zipCode = address.zip_code ? `CEP: ${address.zip_code}` : "";

      if (!street && !city && !state && !zipCode) {
        return "";
      }

      if (!street && !city && !state) {
        return zipCode;
      }

      return `Endereço: ${street} ${city} ${state} ${zipCode}.`;
    },

    /**
     * Email da loja
     * @return {string} email
     */
    storeEmail() {
      return this.list.email;
    },

    /**
     * Retorna o texto cadastrado na loja
     * @return {string} street,
     */
    storeText() {
      return this.list.texts.footer;
    },
  },
};
</script>

<template>
  <p v-if="!storeText()" class="app__footer__credits">
    <span class="app__footer__credit-item" :class="{ app__loading: loading }">
      {{ storeName() }}
    </span>
    <span class="app__footer__credit-item" :class="{ app__loading: loading }">
      {{ storeDocument() }}
    </span>
    <span class="app__footer__credit-item" :class="{ app__loading: loading }">
      {{ storeAddress() }}
    </span>
    <span class="app__footer__credit-item" :class="{ app__loading: loading }">
      {{ storeEmail() }}
    </span>
  </p>

  <p v-else class="app__footer__credits" v-html="storeText()"></p>
</template>
