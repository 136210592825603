import moment from "moment";

/**
 * Define o plugin Moment.js como propriedade do Vue
 */
export default function install(app) {
  // app.config.globalProperties.$dateTime = moment;

  Object.defineProperties(app.config.globalProperties, {
    $dateTime: {
      get() {
        return moment;
      },
    },
  });
}
