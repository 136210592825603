export const setListData = ({ commit }, obj) => {
  commit("setListData", obj);
};

export const setMessages = ({ commit }, obj) => {
  commit("setMessages", obj);
};

export const clearMessages = ({ commit }) => {
  commit("clearMessages");
};

export const setLoading = ({ commit }, obj) => {
  commit("setLoading", obj);
};
