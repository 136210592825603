export default [
  {
    name: "waiting-list",
    path: "/waiting-list",
    meta: {
      title: "Lista de espera",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "waiting-list" */ "../components/main"),
  },
];
