/**
 * Component is lazy-loaded
 * http://router.vuejs.org/en/advanced/lazy-loading.html
 */
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard-page" */ "./main");

export default [
  {
    name: "dashboard",
    path: "/",
    component: Dashboard,
    meta: {
      title: "Minha conta",
      requiresAuth: true,
    },
  },
  {
    name: "catchall", // Caso a rota não exista
    path: "/:pathMatch(.*)*",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
];
