import moment from "moment";
import jwtDecode from "jwt-decode";
import authorize, { getToken } from "../../auth";
import app from "../../../main";

/**
 * Sessão do usuário
 */
export function visitorSessionId() {
  let storeInfo;
  try {
    storeInfo = jwtDecode(getToken());
  } catch (e) {
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
  }

  if (storeInfo) {
    return storeInfo.session;
  }

  return false;
}

/**
 * Define a varíavel $gtm como propriedade do Vue
 */
export const gtm = {
  /**
   * Define o template de pageview do analytics
   * e insere as informações no datalayer
   * @param {Object} route | A rota atual da aplicação
   */
  async trackView(route) {
    let customerLayer = [];
    const dataLayer = window.dataLayer || [];

    const pageCategory =
      typeof route?.value?.meta?.title === "function"
        ? route?.value?.meta?.title(route)
        : route?.value?.meta?.title;

    const { data } = await authorize();

    function ageByDate(birthDate) {
      if (birthDate === "") {
        return "";
      }

      const today = moment().format("YYYY-MM-DD");

      // eslint-disable-next-line max-len
      return Math.floor(
        Math.ceil(
          Math.abs(new Date(birthDate).getTime() - new Date(today).getTime()) /
            (1000 * 3600 * 24)
        ) / 365.25
      );
    }

    customerLayer = {
      pageCategory,
      pageTitle: document.title,
      allow_mail: data?.customer?.newsletter,
      customerEmail: data?.customer?.email,
      customerId: data?.customer?.id,
      customerName: data?.customer?.name,
      customerType: data?.customer?.type,
      customerProfileName: data?.customer?.customer_profile.name,
      customerProfileId: data?.customer?.customer_profile.id,
      visitorDemographicInfo: {
        address: data?.customer?.address,
        age: ageByDate(data?.customer?.birth_date),
        city: data?.customer?.city,
        gender: data?.customer?.gender,
        neighborhood: data?.customer?.neighborhood,
        state: data?.customer?.state,
        zipCode: data?.customer?.zip_code,
      },
      visitorSessionId: visitorSessionId(),
    };

    dataLayer.forEach((element) => {
      if ("customerId" in element) {
        dataLayer.pop();
      }
    });

    dataLayer.push(customerLayer);
  },

  /**
   * @todo
   */
  trackEvent({
    /* eslint-disable no-unused-vars */
    event = null,
    category = null,
    action = null,
    label = null,
    value = null,
  } = {}) {
    // @this.$gtm.trackEvent()
  },
};

/**
 * Após a rota ser alterada dispara o pageview através do Vue.$gtm
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks
 *
 * @param Vue - Instância do Vue
 * @param vueRouter - Instância do Vue Router
 * @param {Array} ignoredViews - Um array com as rotas a serem ignoradas
 */
export function initVueRouterGuard(Vue, vueRouter, ignoreViews = []) {
  vueRouter.afterEach((to) => {
    if (ignoreViews.length) {
      // @todo;
    }

    app.config.globalProperties.$gtm.trackView(to);
  });
}

/**
 * Insere os scripts necessarios para o funcionamento do tag-manager
 * https://developers.google.com/tag-manager/quickstart
 *
 * @param {String} gtmId | ID do google-tag-manager
 */
export function appendScripts(gtmId) {
  const script = document.createElement("script");
  script.async = true;
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${gtmId}');
  `;

  document.head.appendChild(script);

  const gtmNoScript = document.createElement("noscript");
  gtmNoScript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id='${gtmId}'"
      height="0" width="0" style="display:none;visibility:hidden">
    </iframe>`;

  const app = document.getElementById("app");
  document.body.insertBefore(gtmNoScript, app);
}

/**
 * $gtm install
 * Instala o google-tag-manager na aplicação
 */
export default function install(Vue, { id, router }) {
  appendScripts(id);

  /* eslint no-param-reassign: ["error", { "props": false }] */
  app.config.globalProperties.$gtm = gtm;
  // Vue.$gtm = gtm;

  initVueRouterGuard(Vue, router);
  app.config.globalProperties.$gtm.trackView(router.currentRoute);
}
