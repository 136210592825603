/* eslint-disable class-methods-use-this */

import OrderedStepModel from "./steps/ordered-step";
import PaymentStepModel from "./steps/payment-step";
import ShipmentStepModel from "./steps/shipment-step";
import DeliveryStepModel from "./steps/delivery-step";
import DefaultStepModel from "./steps/default-step";

/**
 * @typedef {'Default'|'Ordered'|'Payment'|'Shipment'|'Delivery'|} StepName
 * */
export default class TimelineModel {
  /**
   * TimelineModel's constructor.
   *
   * @param {Array} steps
   * */
  constructor(steps = []) {
    this.steps = steps.map((step) => this.build(step));
  }

  /**
   * Retorna a instância do passo fornecido.
   *
   * @param {object} step
   * @return {StepType}
   * @throws {Error}
   * */
  build(step = {}) {
    const instances = {
      Ordered: OrderedStepModel,
      Payment: PaymentStepModel,
      Shipment: ShipmentStepModel,
      Delivery: DeliveryStepModel,
      Default: DefaultStepModel,
    };

    if (instances[step.name]) {
      return new instances[step.name](step);
    }

    return new instances.Default(step);
  }

  /**
   * Retorna todos os passos do tipo fornecido.
   *
   * @param {StepName} stepName
   * @return {Array<StepType>}
   * */
  allOfType(stepName) {
    return this.steps.filter((step) => step.getStepName() === stepName);
  }

  /**
   * Retorna o primeio passo do tipo fornecido.
   *
   * @param {StepName} stepName
   * @return {StepType|null}
   * */
  firstOfType(stepName) {
    return this.allOfType(stepName).shift() || null;
  }
}
