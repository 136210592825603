import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["storeSettings"]),

    /**
     * Verifica se a configuração de ver os produtos na
     * loja somente após o login esta ativa
     *
     * @return {bool}
     */
    isLoginProduct() {
      return this.storeSettings?.login_product === "1";
    },
  },

  methods: {
    /**
     * Gera um hash simples para evitar o cache do varnish
     *
     * @return {string}
     */
    generateSimpleHash() {
      const now = new Date();
      return now.getTime().toString();
    },

    /**
     * Recebe a url simples e devolve a url já com o hash de acordo com a estrutura:
     *
     * - Se já houver parâmetro na url, o retorno será:
     *   https://exemplo.com.br/loja/central_premiacao.php?loja=696871&hash=123456789
     *
     * - Senão houver o retorno será:
     *   https://exemplo.com.br/?123456789
     *
     * @param {string} url
     * @returns {string}
     */
    mountUrlHash(url) {
      return url.includes("?")
        ? url + "&hash=" + this.generateSimpleHash()
        : url + "?" + this.generateSimpleHash();
    },
  },
};
