<script>
export default {
  name: "AppError",
  methods: {
    /**
     * Recarrega a pagina
     * @returns void
     */
    reload() {
      location.reload();
    },
  },

  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
};
</script>

<template>
  <section class="app__error">
    <div class="app__error__icon">:'(</div>
    <h2 class="app__error__title">
      <slot name="title">
        Encontramos um problema. <br />
        Isso pode ser algo momentâneo, tente carregar novamente.
      </slot>
    </h2>

    <span
      class="app__error__message"
      v-on:click="reload"
      v-if="!hasDefaultSlot"
    >
      clique aqui para tentar novamente
    </span>
    <slot></slot>
  </section>
</template>
