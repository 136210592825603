import BaseModel from "@/store/models/base";

export default class PaymentModel extends BaseModel {
  /**
   * Retorna o identficador do pagamento.
   *
   * @return {number}
   * */
  get id() {
    return this.rawAttributes.id;
  }

  /**
   * Retorna o identficador do método de pagamento.
   *
   * @return {number}
   * */
  get method_id() {
    return this.rawAttributes.method_id;
  }

  /**
   * Retorna o nome do método de pagamento.
   *
   * @return {string}
   * */
  get method_name() {
    return this.rawAttributes.method_name;
  }

  /**
   * Retorna uma descrição do pagamento.
   *
   * @return {string}
   * */
  get note() {
    return this.rawAttributes.note;
  }

  /**
   * Retorna o valor pago
   *
   * @return {number}
   * */
  get value() {
    return this.rawAttributes.value;
  }

  /**
   * Retorna os dados do cartão contidos no atributo note.
   *
   * @return {string}
   * */
  get cardInfoFromNote() {
    const matches = (this.note || "").match(/Cartão:[\s*\d]+/);

    if (!matches) {
      return "";
    }

    const parsedCard = matches[0].replace(/\d{6}/, "******");
    return parsedCard;
  }
}
