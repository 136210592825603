import jwtDecode from "jwt-decode";
import botInterceptor from "./bot-interceptor";

const CRITICAL_POINTS = ["store", "order", "orders"];

/**
 * Retorna o objeto de erro
 * verificando se existe alguma mensagem na resposta
 *
 * @param {object} response
 * @return {object} error
 */
function getError(response) {
  const error = {
    message: "Ocorreu um problema, tente novamente",
  };

  if (response.data && response.data.errors) {
    error.message = response.data.errors.detail || error.message;
  }

  return error;
}

/**
 * Verifica se a request é considerada um ponto critico.
 * @param {string} responseUrl
 * @return {boolean}
 */
function isCriticalPoint(responseUrl) {
  const endpoint = responseUrl.substr(responseUrl.lastIndexOf("/") + 1);
  return (
    CRITICAL_POINTS.filter(
      (criticalpoint) => endpoint.indexOf(criticalpoint) !== -1
    ).length !== 0
  );
}

/**
 * Caso a requisição seja considerada critica
 * define um erro geral para aplicação
 * @param {object} store | vuex
 * @param {*} response | resposta da requisição
 * @return {void}
 */
function setGeneralError(store, response) {
  const responseUrl = response.config.url;

  if (isCriticalPoint(responseUrl)) {
    store.dispatch("setGeneralError", getError(response));
  }
}

/**
 * Redireciona o usuario para pagina de logout da plataforma
 */
function redirectToLogoutPlatform(storeId) {
  if (process.env.NODE_ENV === "production") {
    window.location.replace(
      `${window.location.origin}/loja/logout.php?loja=${storeId}`
    );
  }
}

export default (http, store) => {
  http.interceptors.request.use(botInterceptor);

  /**
   * https://github.com/mzabriskie/axios#interceptors
   */
  http.interceptors.response.use(
    (response) => response,
    /**
     * Ponto central para lidar com qualquer erro gerado pela request HTTP
     */
    (error) => {
      const { response } = error;

      /**
       * Usuário não autenticado
       */
      if ([401].indexOf(response.status) > -1) {
        store.dispatch("setIsUserAuthenticated", false);
        const token = jwtDecode(localStorage.getItem("jwtToken"));
        setGeneralError(store, response);
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("hasPlataformToken");
        redirectToLogoutPlatform(token.store);
      }

      return Promise.reject(response);
    }
  );
};
