import BaseModel from "@/store/models/base";

export default class StatusModel extends BaseModel {
  /**
   * @inheritDoc
   * */
  getSchema() {
    return {
      store: "",
      generic: "em andamento",
      type: "open",
      allow_edit_order: false,
      show: false,
      description: "",
      background_color: "",
    };
  }

  /**
   * Determina se o pedido está aberto.
   *
   * @return {boolean}
   * */
  isOpen() {
    return this.type === "open";
  }

  /**
   * Determina se o pedido está fechado.
   *
   * @return {boolean}
   * */
  isClosed() {
    return this.type === "closed";
  }
  /**
   * Determina se o pedido está cancelado.
   *
   * @return {boolean}
   * */
  isCanceled() {
    return this.type === "canceled";
  }
}
