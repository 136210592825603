export default [
  {
    component: () =>
      import(/* webpackChunkName: "address-list-page" */ "../components/main"),
    path: "/addresses",
    children: [
      {
        name: "addresses.list",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "order-list-page" */ "../components/addresses"
          ),
        meta: {
          title: "Meus Endereços",
          requiresAuth: true,
        },
      },
      {
        name: "addresses.edit",
        path: "/addresses/edit/:addressId",
        meta: {
          title: "Editar endereço",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "address-edit-page" */ "../components/address"
          ),
      },
      {
        name: "addresses.new",
        path: "/addresses/new",
        meta: {
          title: "Novo endereço",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "address-edit-page" */ "../components/address"
          ),
      },
    ],
  },
];
