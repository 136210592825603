export default {
  waitingList: {
    loading: true,
    list: [
      {
        let_me_know_id: "",
        ProductImage: [
          {
            thumbs: {
              180: {
                https: "",
              },
            },
          },
        ],
        url: {
          https: "",
        },
        name: "",
        stock: 0,
        release_date: "",
      },
    ],
    messages: [],
  },
};
