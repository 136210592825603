<script>
import { mapGetters } from "vuex";
import AppSvg from "@/features/general/Svg";

export default {
  name: "AppSecureSeal",
  components: {
    AppSvg,
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    position: {
      default: "footer",
      type: String,
    },
  },
  computed: {
    ...mapGetters(["langs"]),

    /**
     * Verifica se o position foi definido como header
     * @return {Boolean}
     */
    isHeaderInPosition() {
      return this.position === "header" ? true : false;
    },
  },
};
</script>

<template>
  <div
    class="app__footer__security__safe"
    :class="{
      app__loading: this.loading,
      'is-header': this.isHeaderInPosition,
    }"
  >
    <div class="app__footer__security__safe-wrapper">
      <span
        class="app__footer__security__safe-text"
        :class="{
          app__loading: this.loading,
          'is-header': this.isHeaderInPosition,
        }"
        v-html="`${this.langs.pagination['security-text']}`"
      >
      </span>
      <span
        class="app__footer__security__safe-icon"
        :class="{
          app__loading: this.loading,
        }"
      >
        <app-svg name="security" />
      </span>
    </div>
  </div>
</template>
