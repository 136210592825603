export const langs = (state) => state.store_info.list.langs;
export const selectedProfile = (state) => state.customer.selectedProfile;
export const profiles = (state) => state.customer.profiles;
export const customerInfo = (state) => state.customer.list;
export const customerProgress = (state, getters, rootState, rootGetters) => {
  const storeSettings = rootGetters.storeSettings;
  const customer = state.customer.list;
  const vuelidateErrors = state.customer.vuelidateErrors;
  const customerFields = [
    "birth_date",
    "cpf",
    "mobile_phone",
    "cellphone",
    "rg",
    "gender",
    "email",
    "name",
    "phone",
  ];

  const enableEdit = rootGetters.enableEdit;
  if (!enableEdit) {
    customerFields.splice(customerFields.indexOf("gender"), 1);
  }

  const showCustomerPhone = storeSettings.show_customer_phone === "1";
  if (!showCustomerPhone) {
    customerFields.splice(customerFields.indexOf("phone"), 1);
  }

  const showCustomerCellphone = storeSettings.show_customer_cellphone === "1";
  if (!showCustomerCellphone) {
    customerFields.splice(customerFields.indexOf("mobile_phone"), 1);
  }

  const is_foreign = state.customer.list.is_foreign;
  if (is_foreign) {
    customerFields.splice(customerFields.indexOf("cpf"), 1);
    customerFields.splice(customerFields.indexOf("rg"), 1);
  }

  let totalFields = customerFields.length;
  const customerFilledFields = customerFields.filter(
    (field) => !vuelidateErrors[field]
  );

  let totalFilledFields = customerFilledFields.length;
  if (customer.type === "pj") {
    const companyFields = ["company_name", "cnpj", "state_inscription"];

    const companyFilledFields = companyFields.filter(
      (field) => !vuelidateErrors[field]
    );

    totalFilledFields += companyFilledFields.length;
    totalFields += companyFields.length;
  }

  if (Object.entries(customer.customer_attributes).length > 0) {
    const attributesFields = Object.values(customer.customer_attributes);
    const currentType = customer.type === "pj" ? "legal" : "natural";

    const currentTypeAttributes = attributesFields.filter(
      (attr) => attr.person_type === currentType || attr.person_type === "both"
    );
    const currentTypeFilledAttributes = currentTypeAttributes.filter(
      (field) => field.value
    );

    totalFilledFields += currentTypeFilledAttributes.length;
    totalFields += currentTypeAttributes.length;
  }

  const progress = (totalFilledFields / totalFields) * 100;
  return Number(progress.toFixed());
};
