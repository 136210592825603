const InstitucionalDynamicPage = () =>
  import(/* webpackChunkName: "institucional" */ "../components/institucional");

export default [
  {
    name: "login",
    path: "/login",
    meta: {
      title: "Entre em sua conta",
      requiresAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "customer-custom-page" */ "../../Orders/components/main"
      ),
    children: [
      {
        name: "login.main",
        path: "",
        component: () =>
          import(/* webpackChunkName: "login" */ "../components/main"),
      },
      {
        name: "login.institucional",
        path: ":name",
        meta: {
          title: "Institucional",
          requiresAuth: false,
        },
        component: InstitucionalDynamicPage,
      },
    ],
  },
  {
    name: "mail-password-change",
    path: "/mail-password-change/:access_code",
    meta: {
      title: "Troque sua senha",
      requiresAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "mail-password-change" */ "../components/mail-password-change"
      ),
  },
];
