export default {
  address: {
    addresses: [],
    address: {
      id: "",
      active: "",
      city: "",
      complement: "",
      country: "BRA",
      description: "",
      neighborhood: "",
      number: "",
      recipient: "",
      state: "",
      street: "",
      type: "",
      zip_code: "",
    },
    messages: [],
    backendErrors: {
      customer_id: "",
      active: "",
      city: "",
      complement: "",
      country: "",
      id: "",
      description: "",
      neighborhood: "",
      number: "",
      recipient: "",
      state: "",
      type: "",
      zip_code: "",
    },
  },
};
