/**
 * Verifica se a edição de dados está habilitada
 * Lojas que vendem para pessoas fisicas e jurídicas sem atributos customizados obrigatórios
 * @return {boolean}
 */
export const enableEdit = (state) =>
  state.store_info.list.customer_attributes.length === 0;

export const storeInfo = (state) => state.store_info.list;
export const customer = (state) => state.store_info.list.customer;
export const storeIntegrations = (state) => state.store_info.list.integrations;
export const storeSettings = (state) => state.store_info.list.settings.store;
export const mainLoading = (state) => state.loading;
export const isFirstAccess = (state) => state.isFirstAccess;
export const generalError = (state) => state.general_error;
export const storeInfoId = (state) => state.store_info.list.id;
export const isMobile = (state) => state.is_mobile;
export const shipmentTrackersRules = (state) =>
  state.store_info.list.shipment_trackers;
export const langs = (state) => state.store_info.list.langs;
export const dataCallbackPost = (state) =>
  state.store_info.list.dataCallbackPost;
