// https://vuex.vuejs.org/en/actions.html
export default {
  setFirstAccess({ commit }, obj) {
    commit("setFirstAccess", obj);
  },

  setLoading({ commit }, obj) {
    commit("setLoading", obj);
  },

  setMessage({ commit }, obj) {
    commit("setMessage", obj);
  },

  resetMessages({ commit }) {
    commit("resetMessages", { type: "success", message: "" });
    commit("resetMessages", { type: "error", message: [] });
    commit("resetMessages", { type: "warning", message: "" });
    commit("resetMessages", { type: "validation", message: [] });
  },

  setStoreInfo({ commit }, obj) {
    commit("setStoreInfo", obj);
  },

  setGeneralError({ commit }, obj) {
    commit("setGeneralError", obj);
    commit("setLoading", { loading: true });
  },

  setIsMobile({ commit }, windowSize) {
    commit("setIsMobile", windowSize);
  },

  setCustomStyle({ commit }, styles) {
    commit("setCustomStyle", styles);
  },

  setLangs({ commit }, langs) {
    commit("setLangs", langs);
  },

  setDataCallbackPost({ commit }, dataCallbackPost) {
    commit("setDataCallbackPost", dataCallbackPost);
  },

  setIsUserAuthenticated({ commit }, isAuthenticated) {
    commit("setIsUserAuthenticated", isAuthenticated);
  },
};
