import Timeline from "@/features/general/Timeline/models/timeline";

export const setOrdersData = ({ commit }, obj) => {
  commit("setOrdersData", obj);
};

export const setOrdersLoading = ({ commit }, obj) => {
  commit("setOrdersLoading", obj);
};

export const setOrderLoading = ({ commit }, obj) => {
  commit("setOrderLoading", obj);
};

export const setMainLoading = ({ commit }, obj) => {
  commit("setMainLoading", obj);
};

export const setOrderData = ({ commit }, obj) => {
  commit("setOrderData", obj);
};

export const setOrderTimeline = ({ commit }, steps) => {
  commit("setOrderTimeline", new Timeline(steps));
};

export const setTrackerTimeline = ({ commit }, steps) => {
  commit("setTrackerTimeline", new Timeline(steps));
};

export const setTimelineLoading = ({ commit }, obj) => {
  commit("setTimelineLoading", obj);
};

export const setShippingExternalTracker = ({ commit }, obj) => {
  commit("setShippingExternalTracker", obj);
};

export const setOrdersProducts = ({ commit }, obj) => {
  commit("setOrdersProducts", obj);
};

export const setPaymentMethodMessage = ({ commit }, obj) => {
  commit("setPaymentMethodMessage", obj);
};

export const setMessage = ({ commit }, obj) => {
  commit("setMessage", obj);
};

export const clearMessages = ({ commit }) => {
  commit("clearMessages");
};

export const setProductRating = ({ commit }, obj) => {
  commit("setProductRating", obj);
};

export const setProductRatingStars = ({ commit }, value) => {
  commit("setProductRatingStars", value);
};

export const setProductRatingComment = ({ commit }, value) => {
  commit("setProductRatingComment", value);
};

export const deleteProductRating = ({ commit }) => {
  commit("deleteProductRating");
};
