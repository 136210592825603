import BaseModel from "@/store/models/base";

export default class PaymentMethodMessageModel extends BaseModel {
  /**
   * @inheritDoc
   * */
  getSchema() {
    return {
      text: "",
      text_pag: "",
      text_confirm: "",
      confirmation: "",
    };
  }

  /**
   * @return {string}
   */
  get text() {
    return this.rawAttributes.text;
  }

  /**
   * @return {string}
   */
  get textPag() {
    return this.rawAttributes.text_pag;
  }

  /**
   * @return @return {string}
   */
  get textConfirm() {
    return this.rawAttributes.text_confirm;
  }

  /**
   * @return {string}
   */
  get confirmation() {
    return this.rawAttributes.confirmation;
  }
}
