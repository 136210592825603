import BaseModel from "@/store/models/base";
import GiftWrapModel from "./gift-wrap";
import GiftCardModel from "./gift-card";

/**
 * @property {GiftWrapModel[]} GiftWrap
 * @property {GiftCardModel[]} GiftCard
 * */
export default class Extensions extends BaseModel {
  /**
   * {@inheritDoc}
   * */
  getSchema() {
    return {
      GiftWrap: [],
      GiftCard: [],
    };
  }

  /**
   * Transforma o objeto fornecido em uma coleção de GiftWrap.
   *
   * @param {object} entries
   * @private
   * @return {Array<GiftWrapModel>}
   * */
  mutateGiftWrap(entries) {
    const collection = [];
    entries.forEach((entry) => {
      collection.push(new GiftWrapModel(entry));
    });

    return collection;
  }

  /**
   * Transforma o objeto fornecido em uma coleção de GiftCard.
   *
   * @param {object} entries
   * @private
   * @return {Array<GiftCardModel>}
   * */
  mutateGiftCard(entries) {
    const collection = [];
    entries.forEach((entry) => {
      collection.push(new GiftCardModel(entry));
    });

    return collection;
  }

  /**
   * Verifica se existe papéis de embalagem.
   *
   * @return {boolean}
   * */
  hasGiftWrap() {
    return this.GiftWrap && this.GiftWrap.length > 0;
  }

  /**
   * Verifica se existe cartões de presente.
   *
   * @return {boolean}
   * */
  hasGiftCard() {
    return this.GiftCard && this.GiftCard.length > 0;
  }

  /**
   * Retorna o valor total dos embalagens de presente.
   *
   * @return {number}
   * */
  getGiftWrapTotal() {
    return (this.GiftWrap || []).reduce((total, item) => total + item.price, 0);
  }

  /**
   * Retorna o valor total dos cartões de presente.
   *
   * @return {number}
   * */
  getGiftCardTotal() {
    return (this.GiftCard || []).reduce((total, item) => total + item.price, 0);
  }
}
