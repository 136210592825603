<script>
import { mapState } from "vuex";
import AppIcon from "@/features/general/Icons";

export default {
  name: "AppStoreContact",
  components: {
    AppIcon,
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),

    /**
     * E-mail de contato do lojista.
     *
     * @return {string}
     * */
    email() {
      return this.store_info.email;
    },

    /**
     * Telefone de contato lojista.
     *
     * @return {string}
     * */
    phone() {
      return this.store_info.phone;
    },

    /**
     * Retorna o horário de funcionamento da loja.
     *
     * @return {string}
     * */
    officeHour() {
      return this.store_info.office_hour;
    },
  },
};
</script>

<template>
  <div class="app__footer__contact">
    <p class="app__footer__contact__phone" v-if="!!phone">
      <app-icon
        v-if="!!phone"
        class="app__footer__contact__phone-icon"
        prefix="fas"
        name="headset"
      ></app-icon>

      <span class="app__footer__contact__phone-text">
        {{ phone }}
      </span>
    </p>
    <a class="app__footer__contact__email" :href="`mailto:${email}`">{{
      email
    }}</a>
    <p class="app__footer__contact__office-hour" v-html="officeHour"></p>
  </div>
</template>
