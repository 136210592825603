/**
 * @TODO
 * Separar os metodos da store em arquivos separados separados /vuex
 */
const state = {
  timeline: [
    {
      date: "",
      current: false,
      label: "Pedido realizado",
      status: "closed",
      information: {
        label: "",
        link: "",
      },
      icon: {
        name: "clipboard",
      },
    },
    {
      date: "",
      current: false,
      label: "Aguardando pagamento",
      status: "open",
      information: {
        label: "",
        link: "",
      },
      icon: {
        name: "money-bill-alt",
      },
    },
    {
      date: "",
      current: false,
      label: "Envio",
      status: "open",
      information: {
        label: "",
        link: "",
      },
      icon: {
        name: "truck",
        prefix: "fa",
      },
    },
    {
      date: "",
      current: false,
      label: "Previsão de entrega",
      status: "open",
      information: {
        label: "",
        link: "",
      },
      icon: {
        name: "check",
        prefix: "fa",
      },
    },
  ],
};

/* eslint-disable no-param-reassign */
const mutations = {
  setTimelineSteps(st, obj) {
    st.timeline = obj;
  },
};

const actions = {
  setTimelineSteps({ commit }, obj) {
    commit("setTimelineSteps", obj);
  },
};

const module = {
  state,
  mutations,
  actions,
  namespaced: true,
};

export default { module };
